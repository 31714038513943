/*************************************************
// COULEURS DU SITE
*************************************************/

$blanc : #ffffff;
$noir : #262B23;
$gris : #7a8285;
$gris-dark : #171313;
$gris-light : #d5d5d5;

$beige : #F3E1D1;
$marron-clair : #B79875;
$marron : #906A41;
$marron-fonce : #2E3429;


/*************************************************
// CLASS COLORS
*************************************************/

.blanc {
    color: $blanc !important;

    &-bg {
        background: $blanc;
        color: inherit !important;
    }
}

.gris {
    color: $gris !important;

    &-bg {
        background: $gris;
        color: inherit !important;
    }
}

.noir {
    color: $noir !important;

    &-bg {
        background: $noir;
        color: inherit !important;
    }
}

.beige {
    color: $beige !important;

    &-bg {
        background: $beige;
        color: inherit !important;
    }
}

.marron-clair {
    color: $marron-clair !important;

    &-bg {
        background: $marron-clair;
        color: inherit !important;
    }
}

.marron-fonce {
    color: $marron-fonce !important;

    &-bg {
        background: $marron-fonce;
        color: inherit !important;
    }
}

.marron {
    color: $marron !important;

    &-bg {
        background: $marron;
        color: inherit !important;
    }
}