/*************************************************
// STYLES DE CARATERES
*************************************************/

.paragr,
p {
    @include fluid-text(13px, 22px, 1.4, 991px, 1920px);
    font-family: $font;
    font-weight: $regular;
    text-align: left;
    color: $blanc;
    margin: 0;

    strong {
        font-weight: $bold;
    }

    @media(max-width:$screen-md) {
        font-size: 20px;
    }
}

/*************************************************
// TITRES H1/H2/H3
*************************************************/


.h1 {
    margin: 0;
    font-family: $font;
    font-weight: $regular;
    @include fluid-text(35px, 55px, 1.27, 991px, 1920px);
    @include fluid-text(30px, 40px, 1.27, 320px, 390px);
}

.h2 {
    margin: 0;
    font-family: $font;
    font-weight: $regular;
    @include fluid-text(25px, 35px, 1.27, 991px, 1920px);
    @include fluid-text(20px, 30px, 1.27, 320px, 390px);
}

.h3 {
    margin: 0;
    font-family: $font;
    font-weight: $regular;
    @include fluid-text(20px, 30px, 1.43, 991px, 1920px);
    @include fluid-text(13px, 20px, 1.43, 320px, 390px);
}

.nav-text {
    @include fluid-text(14px, 18px, 1.43, 991px, 1920px);
    @include fluid-text(16px, 24px, 1.43, 320px, 390px);  
}

.footer-text {
    @include fluid-text(14px, 18px, 1.43, 991px, 1920px);
    @media screen and (max-width:$screen-md) {
        font-size: 18px;
    }
}
