.bloc1 {
    .h1 {
        margin-bottom: vw(40px);
        @media screen and (max-width: $screen-md) {
            margin-bottom: 25px;
            @include fluid-text(20px, 30px, 1.27, 320px, 390px);
        }
    }
    .gate {
        @media screen and (max-width: $screen-md) {
            margin: 45px 0 0;
        }
    }
    img {
        width: 100%;
    }
    .grid-container {
        @media screen and (min-width: $screen-md) {
            &:not(:last-child) > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: start;
            }
            &:nth-child(1) div {
                justify-content: start;
                p {
                    padding-right: vw(130px);
                }
            }
            &:nth-child(2) {
                z-index: 6;
                .image-figure {
                    margin: -5.21vw 0;
                    @media screen and (max-width: $screen-md) {
                        margin: 0;
                    }
                }
            }
            &:nth-child(4) {
                margin-top: vw(155px);
            }
        }
        @media screen and (max-width: $screen-md) {
            & + .grid-container {
                margin-top: 85px;
            }
            p {
                margin-bottom: 40px;
            }
        }
    }
    &.variante {
        @media screen and (min-width: $screen-md) {
            &:nth-child(odd) {
                .gate {
                    margin-left: -15px;
                }
            }
            p {
                padding-right: vw(150px);
            }
            &:nth-child(even) {
                .gate {
                    margin-left: 15px;
                }
            }
        }
        .bouton {
            margin-top: vw(38px);
            color: $noir;
            min-width: 217px;
            @media screen and (max-width: $screen-md) {
                margin-top: 28px;
            }
        }
        .grid-container:nth-child(2) {
            .image-figure {
                margin: 0;
                @media screen and (min-width: $screen-md) {
                    transform: translateY(-12vw);
                }
            }
            @media screen and (max-width: $screen-md) {
                margin-bottom: 70px;
            }
        }
        .h3 {
            margin-top: vw(127px);
            & + p {
                margin-top: vw(36px);
                @media screen and (max-width: $screen-md) {
                    margin-top: 15px;
                    margin-bottom: 0;
                }
            }
        }
        .h1 + .h3 {
            margin-top: 0;
        }
        p {
            color: $marron-fonce;
        }
    }
}
