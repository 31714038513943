.p-header {
    position: relative;
    z-index: 100;
    background-color: $marron-fonce;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: vw(216px);

    @media screen and (max-width:$screen-md) {
        padding-top: 125px;
    }

    &_bg {
        opacity: 0;
        animation: opacity 1s linear .5s forwards;
        padding: 0 vw(300px);
        margin-top: vw(140px);
        margin-bottom: calc(-51.302vw / 2);

        @media screen and (max-width:$screen-md) {
            margin-top: 50px;
            padding: 0;
            margin-bottom: calc(-60vw / 2);
        }

        img {
            width: 100%;
            height: 51.302vw;
            transform: scale(0.8);
            object-fit: cover;

            @media screen and (max-width:$screen-md) {
                height: 60vw;
            }
        }
    }

    &_titre {
        text-align: center;

        &_bar {
            display: flex;
            justify-content: center;
            opacity: 0;
            transform: translateY(-100%);
            animation: trans-opacity-top-100 1s ease .3s forwards;
            margin-top: vw(100px);
            @media screen and (max-width:$screen-md) {
                margin-top: 40px;
            }
        }

        .h1 {
            color: $marron-clair;
            opacity: 0;
            transform: translateY(-100%);
            animation: trans-opacity-top-100 1s ease .5s forwards;
        }

        h2 {
            @include fluid-text(13px, 20px, 1.27, 991px, 1920px);
            opacity: 0;
            transform: translateY(-100%);
            animation: trans-opacity-top-100 1s ease .7s forwards;
            text-align: center;
            margin: 30px 0 0;

            @media screen and (max-width:$screen-md) {
                font-size: 18px;
                margin: 22px 0 0;
            }
        }
        img {
            opacity: 0;
            animation: opacity 1s ease .3s forwards;

        // &.padding-top {
        //     padding-top: vw(115px);

        //     @media screen and (max-width:$screen-md) {
        //         padding-top: 50px;
        //     }
        }
    }
}