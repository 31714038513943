/*************************************************
// BOOTSTRAP & VARIABLES
*************************************************/
/*************************************************
// GRID
*************************************************/
@import url(vendor/swiper-bundle.css);
@import url(vendor/OverlayScrollbars.css);
@import url(vendor/flatpickr.css);
.simple-container {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (min-width: 992px) {
  .simple-container {
    width: 79.6875vw;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.grid-container {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 992px) {
  .grid-container {
    width: 79.6875vw;
    column-gap: 30px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 992px) {
  .grid-container.no-padding {
    column-gap: 0;
  }
}

.grid {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 992px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-left: 0;
    padding-right: 0;
  }
}

.col-xs-1-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-1-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-1-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-1-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-1-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-1-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-1-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-1-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-1-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-1-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-1-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-1-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-2-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-2-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-2-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-2-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-2-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-2-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-2-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-2-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-2-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-2-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-2-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-2-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-3-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-3-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-3-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-3-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-3-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-3-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-3-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-3-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-3-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-3-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-3-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-3-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-4-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-4-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-4-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-4-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-4-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-4-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-4-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-4-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-4-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-4-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-4-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-4-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-5-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-5-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-5-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-5-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-5-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-5-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-5-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-5-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-5-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-5-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-5-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-5-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 5;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-6-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-6-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-6-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-6-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-6-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-6-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-6-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-6-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-6-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-6-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-6-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-6-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 6;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-7-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-7-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-7-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-7-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-7-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-7-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-7-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-7-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-7-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-7-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-7-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-7-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 7;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-8-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-8-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-8-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-8-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-8-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-8-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-8-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-8-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-8-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-8-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-8-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-8-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 8;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-9-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-9-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-9-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-9-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-9-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-9-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-9-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-9-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-9-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-9-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-9-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-9-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 9;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-10-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-10-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-10-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-10-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-10-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-10-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-10-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-10-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-10-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-10-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-10-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-10-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 10;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-11-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-11-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-11-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-11-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-11-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-11-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-11-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-11-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-11-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-11-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-11-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-11-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 11;
  grid-row-end: span 2;
  grid-column-end: 13;
}

.col-xs-12-1 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 2;
}

.col-xs-12-2 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 3;
}

.col-xs-12-3 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 4;
}

.col-xs-12-4 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 5;
}

.col-xs-12-5 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 6;
}

.col-xs-12-6 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 7;
}

.col-xs-12-7 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 8;
}

.col-xs-12-8 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 9;
}

.col-xs-12-9 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 10;
}

.col-xs-12-10 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 11;
}

.col-xs-12-11 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 12;
}

.col-xs-12-12 {
  max-width: 100%;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 12;
  grid-row-end: span 2;
  grid-column-end: 13;
}

@media (min-width: 760px) {
  .col-sm-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-sm-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-sm-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-sm-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-sm-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-sm-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-sm-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-sm-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-sm-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-sm-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-sm-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-sm-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-sm-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

@media (min-width: 992px) {
  .col-md-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-md-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-md-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-md-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-md-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-md-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-md-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-md-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-md-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-md-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-md-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-md-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-md-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-lg-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-lg-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-lg-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-lg-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-lg-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-lg-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-lg-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-lg-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-lg-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-lg-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-lg-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-lg-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

@media (min-width: 1500px) {
  .col-xl-1-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-1-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-1-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-1-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-1-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-1-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-1-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-1-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-1-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-1-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-1-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-1-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-2-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-2-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-2-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-2-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-2-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-2-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-2-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-2-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-2-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-2-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-2-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-2-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-3-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-3-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-3-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-3-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-3-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-3-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-3-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-3-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-3-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-3-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-3-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-3-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-4-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-4-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-4-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-4-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-4-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-4-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-4-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-4-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-4-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-4-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-4-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-4-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 4;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-5-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-5-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-5-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-5-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-5-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-5-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-5-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-5-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-5-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-5-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-5-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-5-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-6-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-6-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-6-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-6-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-6-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-6-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-6-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-6-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-6-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-6-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-6-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-6-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-7-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-7-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-7-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-7-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-7-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-7-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-7-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-7-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-7-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-7-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-7-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-7-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 7;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-8-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-8-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-8-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-8-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-8-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-8-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-8-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-8-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-8-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-8-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-8-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-8-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 8;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-9-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-9-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-9-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-9-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-9-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-9-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-9-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-9-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-9-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-9-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-9-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-9-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 9;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-10-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-10-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-10-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-10-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-10-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-10-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-10-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-10-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-10-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-10-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-10-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-10-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 10;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-11-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-11-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-11-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-11-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-11-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-11-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-11-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-11-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-11-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-11-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-11-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-11-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 11;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
  .col-xl-12-1 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 2;
  }
  .col-xl-12-2 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 3;
  }
  .col-xl-12-3 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 4;
  }
  .col-xl-12-4 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 5;
  }
  .col-xl-12-5 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 6;
  }
  .col-xl-12-6 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 7;
  }
  .col-xl-12-7 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 8;
  }
  .col-xl-12-8 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 9;
  }
  .col-xl-12-9 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 10;
  }
  .col-xl-12-10 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 11;
  }
  .col-xl-12-11 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 12;
  }
  .col-xl-12-12 {
    max-width: 100%;
    position: relative;
    grid-row-start: 1;
    grid-column-start: 12;
    grid-row-end: span 2;
    grid-column-end: 13;
  }
}

/*************************************************
// FONTS
*************************************************/
/*************************************************
// INCLUDE DES FONTS
*************************************************/
@font-face {
  font-family: 'font';
  src: url("../fonts/PPHatton-Regular.eot");
  src: url("../fonts/PPHatton-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PPHatton-Regular.woff2") format("woff2"), url("../fonts/PPHatton-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*************************************************
// STYLE DES FONTS
*************************************************/
.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

span.tarteaucitronH3, #tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3, .c-actu__title, .c-actu_similar__title, .c-article__title {
  font-family: "font";
  font-weight: normal;
  font-style: normal;
}

.mag_content_title,
.article_content_title, .mag_content_title_sub,
.article_content_title_sub, .c-articles--no-articles {
  font-family: "font";
  font-weight: 700;
  font-style: normal;
}

/*************************************************
// ICOMOON
*************************************************/
/*************************************************
// MIXINS
*************************************************/
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex:after, .flex:before {
  content: normal;
  display: none;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-stretch {
  align-items: stretch;
}

.flex.align-center {
  align-items: center;
}

.flex.justify-center {
  justify-content: center;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.justify-start {
  justify-content: flex-start;
}

.flex.justify-between {
  justify-content: space-between;
}

.flex.column {
  flex-direction: column;
}

.flex.nowrap {
  flex-wrap: nowrap;
}

.flex.wrap {
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .flex.align-start-lg {
    align-items: flex-start;
  }
}

@media (max-width: 992px) {
  .flex.align-start-md {
    align-items: flex-start;
  }
}

@media (max-width: 760px) {
  .flex.align-start-sm {
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .flex.align-start-xs {
    align-items: flex-start;
  }
}

@media (max-width: 1200px) {
  .flex.align-end-lg {
    align-items: flex-end;
  }
}

@media (max-width: 992px) {
  .flex.align-end-md {
    align-items: flex-end;
  }
}

@media (max-width: 760px) {
  .flex.align-end-sm {
    align-items: flex-end;
  }
}

@media (max-width: 480px) {
  .flex.align-end-xs {
    align-items: flex-end;
  }
}

@media (max-width: 1200px) {
  .flex.align-stretch-lg {
    align-items: stretch;
  }
}

@media (max-width: 992px) {
  .flex.align-stretch-md {
    align-items: stretch;
  }
}

@media (max-width: 760px) {
  .flex.align-stretch-sm {
    align-items: stretch;
  }
}

@media (max-width: 480px) {
  .flex.align-stretch-xs {
    align-items: stretch;
  }
}

@media (max-width: 1200px) {
  .flex.align-center-lg {
    align-items: center;
  }
}

@media (max-width: 992px) {
  .flex.align-center-md {
    align-items: center;
  }
}

@media (max-width: 760px) {
  .flex.align-center-sm {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .flex.align-center-xs {
    align-items: stretch;
  }
}

@media (max-width: 1200px) {
  .flex.justify-center-lg {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .flex.justify-center-md {
    justify-content: center;
  }
}

@media (max-width: 760px) {
  .flex.justify-center-sm {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .flex.justify-center-xs {
    justify-content: center;
  }
}

@media (max-width: 1200px) {
  .flex.justify-end-lg {
    justify-content: flex-end;
  }
}

@media (max-width: 992px) {
  .flex.justify-end-md {
    justify-content: flex-end;
  }
}

@media (max-width: 760px) {
  .flex.justify-end-sm {
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .flex.justify-end-xs {
    justify-content: flex-end;
  }
}

@media (max-width: 1200px) {
  .flex.justify-start-lg {
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .flex.justify-start-md {
    justify-content: flex-start;
  }
}

@media (max-width: 760px) {
  .flex.justify-start-sm {
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  .flex.justify-start-xs {
    justify-content: flex-start;
  }
}

@media (max-width: 1200px) {
  .flex.justify-between-lg {
    justify-content: space-between;
  }
}

@media (max-width: 992px) {
  .flex.justify-between-md {
    justify-content: space-between;
  }
}

@media (max-width: 760px) {
  .flex.justify-between-sm {
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .flex.justify-between-xs {
    justify-content: space-between;
  }
}

@media (max-width: 1200px) {
  .flex.column-lg {
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .flex.column-md {
    flex-direction: column;
  }
}

@media (max-width: 760px) {
  .flex.column-sm {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .flex.column-xs {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .flex.column-reverse-lg {
    flex-direction: column-reverse;
  }
}

@media (max-width: 992px) {
  .flex.column-reverse-md {
    flex-direction: column-reverse;
  }
}

@media (max-width: 760px) {
  .flex.column-reverse-sm {
    flex-direction: column-reverse;
  }
}

@media (max-width: 480px) {
  .flex.column-reverse-xs {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .flex.nowrap-lg {
    flex-wrap: nowrap;
  }
}

@media (max-width: 992px) {
  .flex.nowrap-md {
    flex-wrap: nowrap;
  }
}

@media (max-width: 760px) {
  .flex.nowrap-sm {
    flex-wrap: nowrap;
  }
}

@media (max-width: 480px) {
  .flex.nowrap-xs {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1200px) {
  .flex.wrap-lg {
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .flex.wrap-md {
    flex-wrap: wrap;
  }
}

@media (max-width: 760px) {
  .flex.wrap-sm {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .flex.wrap-xs {
    flex-wrap: wrap;
  }
}

/*************************************************
// COULEURS DU SITE
*************************************************/
/*************************************************
// COULEURS DU SITE
*************************************************/
/*************************************************
// CLASS COLORS
*************************************************/
.blanc {
  color: #ffffff !important;
}

.blanc-bg {
  background: #ffffff;
  color: inherit !important;
}

.gris {
  color: #7a8285 !important;
}

.gris-bg {
  background: #7a8285;
  color: inherit !important;
}

.noir {
  color: #262B23 !important;
}

.noir-bg {
  background: #262B23;
  color: inherit !important;
}

.beige {
  color: #F3E1D1 !important;
}

.beige-bg {
  background: #F3E1D1;
  color: inherit !important;
}

.marron-clair {
  color: #B79875 !important;
}

.marron-clair-bg {
  background: #B79875;
  color: inherit !important;
}

.marron-fonce {
  color: #2E3429 !important;
}

.marron-fonce-bg {
  background: #2E3429;
  color: inherit !important;
}

.marron {
  color: #906A41 !important;
}

.marron-bg {
  background: #906A41;
  color: inherit !important;
}

/*************************************************
// BASE
*************************************************/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: common-ligatures;
  -webkit-font-variant-ligatures: common-ligatures;
}

/*************************************************
// BASE
*************************************************/
html {
  font-size: 5.2px;
  font-family: "font";
  color: #262B23;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  font-kerning: none;
}

html.noscroll {
  overflow: hidden;
}

@media (min-width: 760px) {
  html {
    font-size: 6.1px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 7.2px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 8.5px;
  }
}

@media (min-width: 1500px) {
  html {
    font-size: 10px;
  }
}

body {
  min-height: 100%;
  width: 100%;
  font-family: "font";
  position: relative;
  overflow: hidden;
}

#wrapper {
  max-width: 100vw;
  height: 100%;
  overflow: hidden;
}

#wrapper-scroll {
  overflow: hidden;
}

button,
input,
textarea {
  border: none;
  background: none;
}

a,
button {
  transition: all 0.3s ease;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a img {
  border: 0;
}

a:hover,
button:hover {
  text-decoration: none;
}

img,
svg {
  vertical-align: middle;
  border: none;
}

b,
strong {
  font-family: "font";
}

ul,
li {
  list-style: none;
  list-style-type: none;
}

textarea {
  overflow: auto;
  resize: none;
}

/*************************************************
// STYLES DE CARATERES
*************************************************/
/*************************************************
// STYLES DE CARATERES
*************************************************/
.paragr, .p-menu_content, .chambres-informations p strong, .etapes .dates .date, .c-actu__preview, .c-actu__text, .carte_content .categorieTitles_title1,
p {
  font-size: 13px;
  line-height: 1.4;
  font-family: "font";
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

@media (min-width: 991px) {
  .paragr, .p-menu_content, .chambres-informations p strong, .etapes .dates .date, .c-actu__preview, .c-actu__text, .carte_content .categorieTitles_title1,
  p {
    font-size: calc(0.96878vw + 3.39935px);
  }
}

@media (min-width: 1920px) {
  .paragr, .p-menu_content, .chambres-informations p strong, .etapes .dates .date, .c-actu__preview, .c-actu__text, .carte_content .categorieTitles_title1,
  p {
    font-size: 22px;
  }
}

.paragr strong, .p-menu_content strong, .chambres-informations p strong strong, .etapes .dates .date strong, .c-actu__preview strong, .c-actu__text strong, .carte_content .categorieTitles_title1 strong,
p strong {
  font-weight: 700;
}

@media (max-width: 992px) {
  .paragr, .p-menu_content, .chambres-informations p strong, .etapes .dates .date, .c-actu__preview, .c-actu__text, .carte_content .categorieTitles_title1,
  p {
    font-size: 20px;
  }
}

/*************************************************
// TITRES H1/H2/H3
*************************************************/
.h1 {
  margin: 0;
  font-family: "font";
  font-weight: 400;
  font-size: 35px;
  line-height: 1.27;
  font-size: 30px;
  line-height: 1.27;
}

@media (min-width: 991px) {
  .h1 {
    font-size: calc(2.15285vw + 13.66523px);
  }
}

@media (min-width: 1920px) {
  .h1 {
    font-size: 55px;
  }
}

@media (min-width: 320px) {
  .h1 {
    font-size: calc(14.28571vw + -15.71429px);
  }
}

@media (min-width: 390px) {
  .h1 {
    font-size: 40px;
  }
}

.h2 {
  margin: 0;
  font-family: "font";
  font-weight: 400;
  font-size: 25px;
  line-height: 1.27;
  font-size: 20px;
  line-height: 1.27;
}

@media (min-width: 991px) {
  .h2 {
    font-size: calc(1.07643vw + 14.33262px);
  }
}

@media (min-width: 1920px) {
  .h2 {
    font-size: 35px;
  }
}

@media (min-width: 320px) {
  .h2 {
    font-size: calc(14.28571vw + -25.71429px);
  }
}

@media (min-width: 390px) {
  .h2 {
    font-size: 30px;
  }
}

.h3, .etapes .infos .info p, .carte_content .carte_group .carte_product .price {
  margin: 0;
  font-family: "font";
  font-weight: 400;
  font-size: 20px;
  line-height: 1.43;
  font-size: 13px;
  line-height: 1.43;
}

@media (min-width: 991px) {
  .h3, .etapes .infos .info p, .carte_content .carte_group .carte_product .price {
    font-size: calc(1.07643vw + 9.33262px);
  }
}

@media (min-width: 1920px) {
  .h3, .etapes .infos .info p, .carte_content .carte_group .carte_product .price {
    font-size: 30px;
  }
}

@media (min-width: 320px) {
  .h3, .etapes .infos .info p, .carte_content .carte_group .carte_product .price {
    font-size: calc(10vw + -19px);
  }
}

@media (min-width: 390px) {
  .h3, .etapes .infos .info p, .carte_content .carte_group .carte_product .price {
    font-size: 20px;
  }
}

.nav-text, .p-nav {
  font-size: 14px;
  line-height: 1.43;
  font-size: 16px;
  line-height: 1.43;
}

@media (min-width: 991px) {
  .nav-text, .p-nav {
    font-size: calc(0.43057vw + 9.73305px);
  }
}

@media (min-width: 1920px) {
  .nav-text, .p-nav {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .nav-text, .p-nav {
    font-size: calc(11.42857vw + -20.57143px);
  }
}

@media (min-width: 390px) {
  .nav-text, .p-nav {
    font-size: 24px;
  }
}

.footer-text, .p-footer, .p-footer p, .p-footer_nav div, .chambres-informations p {
  font-size: 14px;
  line-height: 1.43;
}

@media (min-width: 991px) {
  .footer-text, .p-footer, .p-footer p, .p-footer_nav div, .chambres-informations p {
    font-size: calc(0.43057vw + 9.73305px);
  }
}

@media (min-width: 1920px) {
  .footer-text, .p-footer, .p-footer p, .p-footer_nav div, .chambres-informations p {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .footer-text, .p-footer, .p-footer p, .p-footer_nav div, .chambres-informations p {
    font-size: 18px;
  }
}

/*************************************************
// PROPRIETES TEXTE
*************************************************/
.underline {
  text-decoration: underline;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
  br:not(.keep) {
    display: none;
  }
}

/*************************************************
// BOUTONS
*************************************************/
/*************************************************
// BOUTONS
*************************************************/
.bouton,
.bouton:focus {
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
  position: relative;
  padding: 5px 15px;
  padding-top: 8px;
  background-color: transparent;
  border: 1px solid #B79875;
  border-radius: 100vmax;
  color: #ffffff;
  font-family: "font";
  transition: all .3s ease;
  font-size: 14px;
  line-height: 1.43;
}

@media (min-width: 991px) {
  .bouton,
  .bouton:focus {
    font-size: calc(0.43057vw + 9.73305px);
  }
}

@media (min-width: 1920px) {
  .bouton,
  .bouton:focus {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .bouton,
  .bouton:focus {
    height: 50px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 18px;
  }
}

.bouton.float-right,
.bouton:focus.float-right {
  float: right;
}

@media screen and (max-width: 992px) {
  .bouton.float-right,
  .bouton:focus.float-right {
    float: none;
  }
}

@media screen and (min-width: 992px) {
  .bouton:hover,
  .bouton:focus:hover {
    background-color: #B79875;
    color: #ffffff;
    transition: all .3s ease;
  }
}

.bouton.v-marron-clair,
.bouton:focus.v-marron-clair {
  border: 1px solid #B79875;
  background-color: #B79875;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .bouton.v-marron-clair:hover,
  .bouton:focus.v-marron-clair:hover {
    background-color: transparent;
    color: #ffffff;
    transition: all .3s ease;
  }
}

/*************************************************
// CLASS UTILITAIRE
*************************************************/
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #262b23 !important;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.noscroll {
  opacity: 0 !important;
  pointer-events: none;
}

@media screen and (max-width: 992px) {
  .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    opacity: 0 !important;
    pointer-events: none;
  }
}

@media screen and (min-width: 992px) {
  .no-link-desktop {
    pointer-events: none;
    cursor: initial;
  }
}

.hidden {
  display: none;
}

@media screen and (min-width: 992px) {
  .hidden-pc {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .hidden-mobile {
    display: none !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.gate {
  border-radius: 100vmax 100vmax 0 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 620 / 880;
}

.gate img {
  height: calc(100% + 10vh);
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.image-figure {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 880 / 620;
}

.image-figure img {
  height: calc(100% + 10vh);
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.img-full img {
  width: 100vw;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 992px) {
  .img-full img {
    aspect-ratio: 1;
  }
}

/*************************************************
// IMPORT DEPENDENCIES
*************************************************/
.p-header {
  position: relative;
  z-index: 100;
  background-color: #2E3429;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
}

@media screen and (max-width: 992px) {
  .p-header {
    padding-top: 125px;
  }
}

.p-header_bg {
  opacity: 0;
  animation: opacity 1s linear .5s forwards;
  padding: 0 27.77778vh;
  margin-top: 12.96296vh;
  margin-bottom: calc(-51.302vw / 2);
}

@media screen and (max-width: 992px) {
  .p-header_bg {
    margin-top: 50px;
    padding: 0;
    margin-bottom: calc(-60vw / 2);
  }
}

.p-header_bg img {
  width: 100%;
  height: 51.302vw;
  transform: scale(0.8);
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .p-header_bg img {
    height: 60vw;
  }
}

.p-header_titre {
  text-align: center;
}

.p-header_titre_bar {
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: translateY(-100%);
  animation: trans-opacity-top-100 1s ease .3s forwards;
  margin-top: 9.25926vh;
}

@media screen and (max-width: 992px) {
  .p-header_titre_bar {
    margin-top: 40px;
  }
}

.p-header_titre .h1 {
  color: #B79875;
  opacity: 0;
  transform: translateY(-100%);
  animation: trans-opacity-top-100 1s ease .5s forwards;
}

.p-header_titre h2 {
  font-size: 13px;
  line-height: 1.27;
  opacity: 0;
  transform: translateY(-100%);
  animation: trans-opacity-top-100 1s ease .7s forwards;
  text-align: center;
  margin: 30px 0 0;
}

@media (min-width: 991px) {
  .p-header_titre h2 {
    font-size: calc(0.7535vw + 5.53283px);
  }
}

@media (min-width: 1920px) {
  .p-header_titre h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .p-header_titre h2 {
    font-size: 18px;
    margin: 22px 0 0;
  }
}

.p-header_titre img {
  opacity: 0;
  animation: opacity 1s ease .3s forwards;
}

.p-footer {
  position: relative;
  z-index: 100;
  background-color: #2E3429;
  color: #ffffff;
  padding-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .p-footer {
    text-align: center;
  }
  .p-footer p,
  .p-footer a {
    text-align: center;
  }
}

.p-footer .simple-container {
  position: relative;
}

.p-footer .line {
  background-color: #B79875;
  width: 100%;
  position: absolute;
  height: 1px;
  top: 172px;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .p-footer .line {
    width: calc(100% - 60px);
  }
}

.p-footer .logo {
  position: relative;
  margin-left: 50%;
  transform: translate(-50%, -37px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-footer .logo::before {
  content: '';
  background-color: #2E3429;
  border-radius: 100vmax;
  position: absolute;
  top: 0;
  width: 125px;
  height: 125px;
  z-index: -1;
}

.p-footer .logo::after {
  content: '';
  background-color: #2E3429;
  position: absolute;
  bottom: 0;
  width: 230px;
  height: 125px;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .p-footer .logo::after {
    width: 174px;
  }
}

.p-footer .logo img {
  width: 139px;
  margin-top: 16px;
}

.p-footer_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .p-footer_grid {
    grid-template-columns: 1fr;
    gap: 90px;
    margin-top: 20px;
  }
}

.p-footer_nav {
  display: flex;
  gap: 9.25926vh;
}

@media screen and (max-width: 992px) {
  .p-footer_nav {
    flex-direction: column;
    align-items: center;
    gap: 34px;
  }
}

.p-footer_nav div {
  gap: 2.77778vh;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .p-footer_nav div {
    gap: 34px;
  }
}

.p-footer_social {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .p-footer_social {
    align-items: center;
    gap: 38px;
  }
}

.p-footer_social .social {
  font-size: 23px;
  font-size: 16px;
  line-height: 1;
  display: flex;
  gap: 2.77778vh;
}

@media (min-width: 991px) {
  .p-footer_social .social {
    font-size: calc(0.7535vw + 8.53283px);
  }
}

@media (min-width: 1920px) {
  .p-footer_social .social {
    font-size: 23px;
  }
}

@media screen and (max-width: 992px) {
  .p-footer_social .social {
    font-size: 23px;
  }
}

@media screen and (max-width: 992px) {
  .p-footer_infos {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-footer_infos::before {
    position: absolute;
    content: '';
    background-color: #B79875;
    width: 100%;
    top: -40px;
    height: 1px;
  }
  .p-footer_infos::after {
    position: absolute;
    content: '';
    background-color: #B79875;
    width: 100%;
    bottom: -40px;
    height: 1px;
  }
}

.p-footer .copyright {
  margin-top: 7.87037vh;
}

.p-footer .copyright p {
  font-size: 13px;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .p-footer .copyright {
    margin-top: 40px;
  }
}

.p-footer a:hover {
  color: #B79875;
}

.periodeOuverture {
  width: 100%;
  background-color: #F3E1D1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 7rem;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 992px) {
  .periodeOuverture {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

.periodeOuverture .h2 {
  font-weight: bold;
  color: #2E3429;
}

@media screen and (max-width: 992px) {
  .periodeOuverture .h2 {
    text-align: center;
  }
}

.periodeOuverture_content {
  margin-top: 30px;
}

@media screen and (min-width: 992px) {
  .periodeOuverture_content {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.periodeOuverture_content p {
  color: #906A41;
}

@media screen and (max-width: 992px) {
  .periodeOuverture_content p {
    text-align: center;
  }
}

.periodeOuverture_content p + p {
  margin-left: 3vw;
}

@media screen and (max-width: 992px) {
  .periodeOuverture_content p + p {
    margin-top: 25px;
  }
}

.p-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 104;
  width: 100%;
  background-color: #2E3429;
  opacity: 0;
  transform: translateY(100%);
  animation: trans-opacity-bot-100 1s ease .3s forwards;
  color: #ffffff;
}

.p-nav a:hover {
  color: #B79875;
}

.p-nav .logo {
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .p-nav .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 0;
    transform: translate(-50%, -50%);
  }
}

.p-nav .logo img {
  max-width: 30vw;
  width: 100%;
  min-width: 100px;
  height: auto;
}

@media screen and (max-width: 992px) {
  .p-nav .logo img {
    max-width: 150px;
  }
}

.p-nav_container {
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 992px) {
  .p-nav_container {
    height: 85px;
  }
}

.p-nav_menu {
  display: flex;
  align-items: center;
  gap: 3.05556vh;
}

@media screen and (max-width: 992px) {
  .p-nav_menu {
    display: none;
  }
}

.p-nav_menu2 {
  display: flex;
  align-items: center;
  gap: 2.22222vh;
}

@media screen and (max-width: 992px) {
  .p-nav_menu2 {
    display: initial;
    font-size: 14px;
  }
  .p-nav_menu2 .bouton {
    display: none;
  }
}

.p-nav.masked {
  background-color: transparent;
}

.p-nav .bt-menu {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 992px) {
  .p-nav .bt-menu {
    display: none;
  }
}

.p-nav .bt-menu .barre {
  display: block;
  width: 100%;
  background-color: #ffffff;
  height: 2px;
  position: relative;
  transform: rotate(0);
  transition: all .3s ease;
}

.p-nav .bt-menu .barre:nth-child(1) {
  top: -11px;
  transition: all .3s ease;
}

.p-nav .bt-menu .barre:nth-child(2) {
  opacity: 1;
  transition: all .3s ease;
}

.p-nav .bt-menu .barre:nth-child(3) {
  bottom: -11px;
  transition: all .3s ease;
  width: 70%;
}

.p-nav .bt-menu.open .barre {
  transition: all .3s ease;
}

.p-nav .bt-menu.open .barre:nth-child(1) {
  width: 70%;
  top: 2px;
  transform: rotate(45deg);
  transition: top .3s ease, transform .3s ease .2s;
}

.p-nav .bt-menu.open .barre:nth-child(2) {
  opacity: 0;
  transition: all .1s ease;
}

.p-nav .bt-menu.open .barre:nth-child(3) {
  bottom: 2px;
  transform: rotate(-45deg);
  transition: bottom .3s ease, transform .3s ease .2s;
}

.p-menu {
  position: fixed;
  background-color: #2E3429;
  top: -140%;
  bottom: 140%;
  left: 0;
  z-index: 103;
  width: 100%;
  transition: all 0.4s ease 0.3s;
}

.p-menu_content {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .p-menu_content {
    height: 100%;
    padding-top: 110px;
  }
}

.p-menu .logo img {
  max-height: 25vh;
  margin-bottom: 5vh;
}

.p-menu .line {
  height: 1px;
  width: 57px;
  background-color: #B79875;
}

.p-menu_nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5vh;
}

.p-menu_bottom {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 50px;
}

.p-menu_bottom .item {
  border: 1px solid #B79875;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.p-menu .anim-link {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s ease;
}

.p-menu.open {
  top: 0;
  bottom: 0;
  transition: all 0.4s ease 0.2s;
}

.p-menu.open .anim-link {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s ease;
}

.p-menu.open .anim-link:nth-child(1) {
  transition-delay: 0.4s;
}

.p-menu.open .anim-link:nth-child(2) {
  transition-delay: 0.5s;
}

.p-menu.open .anim-link:nth-child(3) {
  transition-delay: 0.6s;
}

.p-menu.open .anim-link:nth-child(4) {
  transition-delay: 0.7s;
}

.p-menu.open .anim-link:nth-child(5) {
  transition-delay: 0.8s;
}

.p-menu.open .anim-link:nth-child(6) {
  transition-delay: 0.9s;
}

.p-menu.open .anim-link:nth-child(7) {
  transition-delay: 1s;
}

.p-menu.open .anim-link:nth-child(8) {
  transition-delay: 1.1s;
}

.p-menu.open .anim-link:nth-child(9) {
  transition-delay: 1.2s;
}

.p-menu.open .anim-link:nth-child(10) {
  transition-delay: 1.3s;
}

.p-menu.open .anim-link:nth-child(11) {
  transition-delay: 1.4s;
}

.p-menu.open .anim-link:nth-child(12) {
  transition-delay: 1.5s;
}

.p-menu.open .anim-link:nth-child(13) {
  transition-delay: 1.6s;
}

.p-menu.open .anim-link:nth-child(14) {
  transition-delay: 1.7s;
}

.p-menu.open .anim-link:nth-child(15) {
  transition-delay: 1.8s;
}

.p-menu.open .anim-link:nth-child(16) {
  transition-delay: 1.9s;
}

.p-menu.open .anim-link:nth-child(17) {
  transition-delay: 2s;
}

.p-menu.open .anim-link:nth-child(18) {
  transition-delay: 2.1s;
}

.p-menu.open .anim-link:nth-child(19) {
  transition-delay: 2.2s;
}

.p-menu.open .anim-link:nth-child(20) {
  transition-delay: 2.3s;
}

.p-menu.open .anim-link:nth-child(21) {
  transition-delay: 2.4s;
}

.p-menu.open .anim-link:nth-child(22) {
  transition-delay: 2.5s;
}

.p-menu.open .anim-link:nth-child(23) {
  transition-delay: 2.6s;
}

.p-menu.open .anim-link:nth-child(24) {
  transition-delay: 2.7s;
}

.p-menu.open .anim-link:nth-child(25) {
  transition-delay: 2.8s;
}

.p-menu.open .anim-link:nth-child(26) {
  transition-delay: 2.9s;
}

.p-menu.open .anim-link:nth-child(27) {
  transition-delay: 3s;
}

.p-menu.open .anim-link:nth-child(28) {
  transition-delay: 3.1s;
}

.p-menu.open .anim-link:nth-child(29) {
  transition-delay: 3.2s;
}

.p-menu.open .anim-link:nth-child(30) {
  transition-delay: 3.3s;
}

.home .p-header {
  padding-top: 9.25926vh;
}

@media screen and (max-width: 992px) {
  .home .p-header {
    padding-top: 100px;
  }
}

.home::after {
  z-index: -1;
  content: "";
  background-color: #F3E1D1;
  height: 100%;
  width: calc(42.44vw + 30px);
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .home::after {
    display: none;
  }
}

.home .p-nav.masked .logo {
  opacity: 0;
  pointer-events: none;
}

.home-section1 {
  position: relative;
  padding-top: calc(51.302vw / 2 + 12.4vw);
  margin-bottom: -11.46vw;
}

@media screen and (max-width: 992px) {
  .home-section1 {
    padding-top: calc(60vw / 2 + 70px);
    margin-bottom: -47px;
    background-color: #F3E1D1;
  }
}

@media screen and (max-width: 992px) {
  .home-section1::after {
    content: "";
    background-color: #F3E1D1;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ffffff;
    width: 100%;
    height: 645px;
  }
}

@media screen and (max-width: 992px) {
  .home .img-full1 img {
    aspect-ratio: 390/290;
  }
}

.home-section2 {
  background-color: #2E3429;
  padding: 20.37037vh 0 24.07407vh;
}

.home-section2 .flex {
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .home-section2 {
    padding: 110px 0 120px;
  }
  .home-section2 .h1 {
    padding: 70px 0;
  }
}

.home-section3 {
  background-color: #2E3429;
  padding-bottom: 17.59259vh;
}

@media screen and (max-width: 992px) {
  .home-section3 {
    padding-bottom: 128px;
  }
}

.home-section3_grid {
  margin-top: 7.40741vh;
  column-gap: 14.81481vh;
  row-gap: 9.25926vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .home-section3_grid {
    grid-template-columns: 1fr;
    gap: 80px;
    margin-top: 80px;
  }
}

.home-section3_grid .item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.7037vh;
  width: 21.2963vh;
}

@media screen and (max-width: 992px) {
  .home-section3_grid .item {
    gap: 40px;
    width: 100%;
  }
}

.home-section3_grid .item p {
  text-align: center;
  white-space: nowrap;
}

.home-section4 {
  position: relative;
}

.home-section4 .h1 {
  position: absolute;
  top: 11.48148vh;
}

@media screen and (max-width: 992px) {
  .home-section4 .h1 {
    top: 80px;
    text-align: center;
    width: calc(100% - 60px);
    font-size: 30px;
  }
}

@media screen and (max-width: 992px) {
  .home-section4 .img-full2 img {
    aspect-ratio: 390/600;
  }
}

.home-section5 {
  background: linear-gradient(to right, #F3E1D1 42.44%, #ffffff 42.44%);
  padding-bottom: 31.48148vh;
}

@media screen and (max-width: 992px) {
  .home-section5 {
    background: linear-gradient(to top, #F3E1D1 550px, #ffffff 550px);
    padding-bottom: 230px;
  }
}

.home-section5 .h1 {
  margin-top: 18.51852vh;
  margin-bottom: 3.88889vh;
}

@media screen and (max-width: 992px) {
  .home-section5 .h1 {
    margin: 40px 0;
  }
}

.home-section5 .gate {
  margin-top: -15vw;
}

@media screen and (max-width: 992px) {
  .home-section5 .gate {
    margin-top: -140px;
  }
}

.home-section5 .bouton {
  color: #262B23;
  margin-top: 6.48148vh;
}

@media screen and (max-width: 992px) {
  .home-section5 .bouton {
    margin-top: 100px;
  }
}

.home-section5 .h3, .home-section5 .etapes .infos .info p, .etapes .infos .info .home-section5 p, .home-section5 .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .home-section5 .price {
  margin-top: -2.6vw;
  padding-right: 9.25926vh;
}

@media screen and (max-width: 992px) {
  .home-section5 .h3, .home-section5 .etapes .infos .info p, .etapes .infos .info .home-section5 p, .home-section5 .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .home-section5 .price {
    margin-top: 116px;
    padding-right: 0;
    font-size: 15px;
    line-height: 1.44;
  }
}

@media screen and (max-width: 992px) and (min-width: 320px) {
  .home-section5 .h3, .home-section5 .etapes .infos .info p, .etapes .infos .info .home-section5 p, .home-section5 .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .home-section5 .price {
    font-size: calc(14.28571vw + -30.71429px);
  }
}

@media screen and (max-width: 992px) and (min-width: 390px) {
  .home-section5 .h3, .home-section5 .etapes .infos .info p, .etapes .infos .info .home-section5 p, .home-section5 .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .home-section5 .price {
    font-size: 25px;
  }
}

.home-section6 {
  position: relative;
  background-color: #2E3429;
}

.home-section6 .col {
  position: relative;
  transform: translateY(-50%);
}

@media screen and (max-width: 992px) {
  .home-section6 .col + .col {
    margin-top: 30px;
  }
}

.home-section6 .col::before {
  z-index: 5;
  content: '';
  background-color: #101010;
  opacity: .4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-section6 .col::after {
  z-index: 6;
  content: '';
  background-color: #906A41;
  opacity: .8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all .3s ease;
}

.home-section6 .col:hover::after {
  opacity: .8;
}

.home-section6 .fond {
  position: relative;
  width: 100%;
}

.home-section6 .container {
  z-index: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  gap: 3.14815vh;
}

.home-section7 {
  background-color: #2E3429;
  padding-bottom: 25vh;
}

@media screen and (max-width: 992px) {
  .home-section7 {
    padding-bottom: 100px;
  }
}

.home-section7 .h1 {
  margin-bottom: 9.25926vh;
}

@media screen and (max-width: 992px) {
  .home-section7 .h1 {
    margin-bottom: 70px;
  }
}

.home-section8 {
  position: relative;
  padding-top: 23.51852vh;
}

@media screen and (max-width: 992px) {
  .home-section8 {
    padding-top: 70px;
    background-color: #F3E1D1;
  }
}

.home-section8::after {
  content: "";
  background-color: #F3E1D1;
  height: 100%;
  width: calc(50vw);
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .home-section8::after {
    background-color: #ffffff;
    width: 100%;
    height: 645px;
  }
}

.restaurant::after {
  z-index: -1;
  content: "";
  background-color: #F3E1D1;
  height: 100%;
  width: calc(42.44vw + 30px);
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .restaurant::after {
    display: none;
  }
}

.restaurant-section1 {
  position: relative;
  padding-top: calc(51.302vw / 2 + 12.4vw);
  margin-bottom: -11.46vw;
}

@media screen and (max-width: 992px) {
  .restaurant-section1 {
    padding-top: calc(60vw / 2 + 70px);
    margin-bottom: -47px;
    background-color: #F3E1D1;
  }
}

@media screen and (max-width: 992px) {
  .restaurant-section1::after {
    content: "";
    background-color: #F3E1D1;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ffffff;
    width: 100%;
    height: 645px;
  }
}

.restaurant-section1 .bloc1_carre {
  margin-top: 4.62963vh;
  width: 49rem;
  padding: 3.24074vh 4.62963vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .restaurant-section1 .bloc1_carre {
    margin-top: 50px;
    padding: 35px 15px;
    width: 100%;
  }
}

.restaurant-section1 .bloc1_carre p {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
}

.restaurant-carte {
  background-color: #2E3429;
  padding: 37.03704vh 0 27.77778vh;
}

@media screen and (max-width: 992px) {
  .restaurant-carte {
    padding: 180px 0 100px;
  }
}

.restaurant-carte .h1 + p {
  margin: 15px 0 9.25926vh;
}

@media screen and (max-width: 992px) {
  .restaurant-carte .h1 + p {
    margin-bottom: 75px;
  }
}

.chambres::after {
  z-index: -1;
  content: "";
  background-color: #F3E1D1;
  height: 100%;
  width: calc(42.44vw + 30px);
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .chambres::after {
    display: none;
  }
}

.chambres-section1 {
  position: relative;
  padding-top: calc(51.302vw / 2 + 12.4vw);
  padding-bottom: 25vh;
}

@media screen and (max-width: 992px) {
  .chambres-section1 {
    padding-top: calc(60vw / 2 + 70px);
    padding-bottom: 100px;
    background-color: #F3E1D1;
  }
}

@media screen and (max-width: 992px) {
  .chambres-section1::after {
    content: "";
    background-color: #F3E1D1;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ffffff;
    width: 100%;
    height: 645px;
  }
}

@media screen and (min-width: 992px) {
  .chambres-section1 .bloc1 .grid-container:not(:first-child) {
    align-items: center;
  }
  .chambres-section1 .bloc1 .grid-container:nth-child(2) .image-figure {
    margin: -5.21vw 0;
  }
}

.chambres-section2 {
  background-color: #2E3429;
  padding: 18.51852vh 0;
}

@media screen and (max-width: 992px) {
  .chambres-section2 {
    padding: 75px 0 110px;
  }
  .chambres-section2 .h3, .chambres-section2 .etapes .infos .info p, .etapes .infos .info .chambres-section2 p, .chambres-section2 .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .chambres-section2 .price {
    font-size: 25px;
    line-height: 1.43;
  }
}

@media screen and (max-width: 992px) and (min-width: 320px) {
  .chambres-section2 .h3, .chambres-section2 .etapes .infos .info p, .etapes .infos .info .chambres-section2 p, .chambres-section2 .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .chambres-section2 .price {
    font-size: calc(14.28571vw + -20.71429px);
  }
}

@media screen and (max-width: 992px) and (min-width: 390px) {
  .chambres-section2 .h3, .chambres-section2 .etapes .infos .info p, .etapes .infos .info .chambres-section2 p, .chambres-section2 .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .chambres-section2 .price {
    font-size: 35px;
  }
}

.chambres-section2_grid {
  margin-top: 7.40741vh;
  column-gap: 13.88889vh;
  row-gap: 9.25926vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .chambres-section2_grid {
    grid-template-columns: 1fr 1fr;
    row-gap: 80px;
    column-gap: 30px;
    justify-content: space-between;
    margin-top: 80px;
  }
}

.chambres-section2_grid .item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.7037vh;
}

@media screen and (max-width: 992px) {
  .chambres-section2_grid .item {
    gap: 40px;
  }
}

.chambres-section2_grid .item p {
  text-align: center;
  white-space: nowrap;
}

.chambres-section3 {
  position: relative;
  padding-top: 23.51852vh;
}

@media screen and (max-width: 992px) {
  .chambres-section3 {
    padding-top: 70px;
    background-color: #F3E1D1;
  }
}

.chambres-section3::after {
  content: "";
  background-color: #F3E1D1;
  height: 100%;
  width: calc(50vw);
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {
  .chambres-section3::after {
    background-color: #ffffff;
    width: 100%;
    height: 645px;
  }
}

.chambres-informations {
  background-color: #2E3429;
  padding-top: 17.12963vh;
  padding-bottom: 14.81481vh;
}

@media screen and (max-width: 992px) {
  .chambres-informations {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.chambres-informations .h1 {
  margin-bottom: 3.24074vh;
}

@media screen and (max-width: 992px) {
  .chambres-informations .h1 {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 992px) {
  .chambres-informations p {
    margin-top: 40px;
  }
}

.chambres-informations p strong,
.chambres-informations p span {
  color: #B79875;
}

.chambres-informations p br {
  display: block !important;
}

.chambres-informations p + p {
  margin-top: 3.7037vh;
}

.activites_content,
.partenaires_content {
  background-color: #2E3429;
  padding-top: 13.88889vh;
  padding-bottom: 20.37037vh;
}

@media screen and (max-width: 992px) {
  .activites_content,
  .partenaires_content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.activites_content .c-article__infos,
.partenaires_content .c-article__infos {
  background-color: #F3E1D1;
}

.activites_content .c-article__infos .bouton,
.partenaires_content .c-article__infos .bouton {
  color: #2E3429;
}

.activites_content .c-article__title,
.partenaires_content .c-article__title {
  color: #2E3429;
}

.activites_content .c-article__date,
.partenaires_content .c-article__date {
  color: #2E3429;
}

.activites_content .c-article__preview,
.partenaires_content .c-article__preview {
  color: #B79875;
}

.partenaires .p-header_bg {
  background-image: url("../img/header/");
}

.contact_header {
  padding-top: 23.14815vh;
  padding-bottom: 17.59259vh;
  background-color: #2E3429;
}

@media screen and (max-width: 992px) {
  .contact_header {
    padding-top: 150px;
    padding-bottom: 100px;
  }
}

.contact_header_title {
  opacity: 0;
  transform: translateY(-100%);
  animation: trans-opacity-top-100 1s ease .3s forwards;
  color: #B79875;
  margin-bottom: 4.16667vh;
}

@media screen and (max-width: 992px) {
  .contact_header_title {
    margin-bottom: 45px;
  }
}

.contact_header_text {
  opacity: 0;
  transform: translateY(-100%);
  animation: trans-opacity-top-100 1s ease .5s forwards;
  color: #ffffff;
  max-width: 30.5rem;
  font-size: 11px;
  line-height: 1.43;
  font-size: 16px;
  line-height: 1.43;
}

@media (min-width: 991px) {
  .contact_header_text {
    font-size: calc(0.96878vw + 1.39935px);
  }
}

@media (min-width: 1920px) {
  .contact_header_text {
    font-size: 20px;
  }
}

@media (min-width: 320px) {
  .contact_header_text {
    font-size: calc(5.71429vw + -2.28571px);
  }
}

@media (min-width: 390px) {
  .contact_header_text {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .contact_header_text {
    max-width: 100%;
  }
}

.contact_header_text + p {
  margin-top: 3.24074vh;
}

@media screen and (max-width: 992px) {
  .contact_header_text + p {
    margin-top: 40px;
  }
}

.contact_header_text a {
  color: #B79875;
}

.contact_header .c-formulaire {
  opacity: 0;
  animation: opacity 1s ease .8s forwards;
}

@media screen and (max-width: 992px) {
  .contact_header .c-formulaire {
    margin-top: 80px;
  }
}

.contact_map {
  background-color: #F3E1D1;
}

@media screen and (min-width: 992px) {
  .contact_map {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.contact_map_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 19.44444vh;
  padding-bottom: 21.2963vh;
}

@media screen and (max-width: 992px) {
  .contact_map_left {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

.contact_map_left .logo {
  width: 26.85185vh;
}

@media screen and (max-width: 992px) {
  .contact_map_left .logo {
    width: 215px;
    max-width: 80%;
  }
}

.contact_map_left .logo img {
  width: 100%;
  height: auto;
}

.contact_map_left p {
  color: #906A41;
  margin-top: 2.77778vh;
}

@media screen and (max-width: 992px) {
  .contact_map_left p {
    margin-top: 40px;
  }
}

.contact_map_right {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .contact_map_right {
    height: 595px;
  }
}

.legals {
  background-color: #2E3429;
}

.legals #section-top {
  padding-top: 20rem;
  padding-bottom: 10rem;
}

@media screen and (max-width: 992px) {
  .legals #section-top {
    padding-top: 150px;
    padding-bottom: 100px;
  }
}

.legals_titre {
  margin-bottom: 6rem;
}

.legals_texte {
  padding: 10rem 0;
}

.legals_texte p + p {
  margin-top: 30px;
}

.legals_subtitle {
  display: block;
  color: #ffffff;
  font-size: 18px;
  margin-top: 80px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .legals_subtitle {
    font-size: 24px;
  }
}

.error404 {
  background-color: #2E3429;
}

.error404 .p-header,
.error404 .p-nav,
.error404 .p-footer {
  display: none;
}

.error404_content {
  height: 100vh;
}

.error404_content_container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.error404_content .flex {
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 6.94444vh;
}

@media screen and (max-width: 992px) {
  .error404_content .flex {
    align-items: center;
    gap: 3.5vh;
  }
}

.error404_content_h1 {
  font-size: 100px;
  line-height: 1.1;
}

@media (min-width: 991px) {
  .error404_content_h1 {
    font-size: calc(9.90312vw + 1.86006px);
  }
}

@media (min-width: 1920px) {
  .error404_content_h1 {
    font-size: 192px;
  }
}

@media screen and (max-width: 992px) {
  .error404_content_h1 {
    text-align: center;
  }
}

.error404_content_h2 {
  font-size: 30px;
  line-height: 1.1;
}

@media (min-width: 991px) {
  .error404_content_h2 {
    font-size: calc(1.07643vw + 19.33262px);
  }
}

@media (min-width: 1920px) {
  .error404_content_h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 992px) {
  .error404_content_h2 {
    text-align: center;
  }
}

.error404_content img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .error404_content img {
    width: 200px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 6vh;
  }
}

.u-banner-cookies {
  position: fixed;
  bottom: 30px;
  left: 30px;
  transform: translateY(170%);
  z-index: 100000;
  overflow: hidden;
  opacity: 0;
  transition: all .5s ease .25s;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies {
    bottom: 15px;
    left: 30px;
    width: 100%;
  }
}

.u-banner-cookies_content {
  width: 48rem;
  background-color: #262B23;
  padding: 3rem 4rem;
  border-radius: 3rem;
  border: 1px solid #906A41;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies_content {
    width: calc(100vw - 60px);
    padding: 15px;
    display: block;
  }
}

.u-banner-cookies .bot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.u-banner-cookies .bot .bouton {
  min-width: 16rem;
  padding: 0 2rem;
  font-size: 1.4rem;
  height: 3.5rem;
  border-radius: 2.5rem;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies .bot {
    width: 100%;
    margin-left: 0;
    flex-direction: row;
  }
  .u-banner-cookies .bot .bouton {
    width: calc(50% - 5px);
    min-width: 0;
    margin: 0;
    height: 40px;
    font-size: 14px;
    border-radius: 25px;
  }
}

.u-banner-cookies_parametre {
  font-family: "font";
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  margin-top: 2rem;
  text-decoration: underline;
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies_parametre {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.u-banner-cookies.active {
  transform: translateY(0);
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .u-banner-cookies.active {
    transform: translateY(0) translateX(0);
  }
}

#tarteaucitronAlertBig {
  display: none !important;
}

/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important;
  }
  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important;
  }
  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }
  li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
    content: '\0025BE';
    font-weight: 700;
    font-size: 14px;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
    content: '\0025B4';
  }
}

@media screen and (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
    content: '\0025BE';
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
    content: '\0025B4';
    margin-left: 15px;
  }
}

/****/
/***
* Reset CSS
*/
#tarteaucitronRoot div,
#tarteaucitronRoot span,
#tarteaucitronRoot applet,
#tarteaucitronRoot object,
#tarteaucitronRoot iframe,
#tarteaucitronRoot h1,
#tarteaucitronRoot h2,
#tarteaucitronRoot h3,
#tarteaucitronRoot h4,
#tarteaucitronRoot h5,
#tarteaucitronRoot h6,
#tarteaucitronRoot p,
#tarteaucitronRoot blockquote,
#tarteaucitronRoot pre,
#tarteaucitronRoot a,
#tarteaucitronRoot abbr,
#tarteaucitronRoot acronym,
#tarteaucitronRoot address,
#tarteaucitronRoot big,
#tarteaucitronRoot cite,
#tarteaucitronRoot code,
#tarteaucitronRoot del,
#tarteaucitronRoot dfn,
#tarteaucitronRoot em,
#tarteaucitronRoot img,
#tarteaucitronRoot ins,
#tarteaucitronRoot kbd,
#tarteaucitronRoot q,
#tarteaucitronRoot s,
#tarteaucitronRoot samp,
#tarteaucitronRoot small,
#tarteaucitronRoot strike,
#tarteaucitronRoot strong,
#tarteaucitronRoot sub,
#tarteaucitronRoot sup,
#tarteaucitronRoot tt,
#tarteaucitronRoot var,
#tarteaucitronRoot b,
#tarteaucitronRoot u,
#tarteaucitronRoot i,
#tarteaucitronRoot center,
#tarteaucitronRoot dl,
#tarteaucitronRoot dt,
#tarteaucitronRoot dd,
#tarteaucitronRoot ol,
#tarteaucitronRoot ul,
#tarteaucitronRoot li,
#tarteaucitronRoot fieldset,
#tarteaucitronRoot form,
#tarteaucitronRoot label,
#tarteaucitronRoot legend,
#tarteaucitronRoot table,
#tarteaucitronRoot caption,
#tarteaucitronRoot tbody,
#tarteaucitronRoot tfoot,
#tarteaucitronRoot thead,
#tarteaucitronRoot tr,
#tarteaucitronRoot th,
#tarteaucitronRoot td,
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot canvas,
#tarteaucitronRoot details,
#tarteaucitronRoot embed,
#tarteaucitronRoot figure,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot output,
#tarteaucitronRoot ruby,
#tarteaucitronRoot section,
#tarteaucitronRoot summary,
#tarteaucitronRoot time,
#tarteaucitronRoot mark,
#tarteaucitronRoot audio,
#tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot details,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot figure,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol,
#tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote,
#tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote:before,
#tarteaucitronRoot blockquote:after,
#tarteaucitronRoot q:before,
#tarteaucitronRoot q:after {
  content: '';
  content: none;
}

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible,
#tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 26px !important;
  height: auto !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }
  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}

@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer,
  #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }
  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important;
  }
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }
  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}

#tarteaucitron {
  border-radius: 0 !important;
}

#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  top: 50% !important;
  transform: translateY(-50%);
  margin: auto;
}

@media screen and (max-width: 1500px) {
  #tarteaucitronRoot div#tarteaucitron {
    overflow: scroll;
  }
}

#tarteaucitron .clear {
  clear: both;
}

.tarteaucitronListCookies {
  display: block;
}

#tarteaucitron a {
  color: #262B23;
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong,
#tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a,
#tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1,
#tarteaucitron .tarteaucitronH2,
#tarteaucitron .tarteaucitronH3,
#tarteaucitron .tarteaucitronH4,
#tarteaucitron .tarteaucitronH5,
#tarteaucitron .tarteaucitronH6 {
  display: block;
}

span.tarteaucitronH3 {
  font-weight: 700 !important;
  color: #ffffff !important;
  font-size: 16px !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-size: 16px !important;
}

#tarteaucitronRoot * {
  color: #262B23;
  background-color: #ffffff;
}

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: #262B23;
  margin: 0 25px;
  margin-bottom: 4rem;
}

@media screen and (max-width: 992px) {
  #tarteaucitronRoot .tarteaucitronH1 {
    font-size: 25px;
    margin-bottom: 40px;
  }
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #262B23;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #262B23;
}

#tarteaucitronBack {
  background: #262B23;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
  border-radius: 3rem;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  height: auto;
  overflow: auto;
}

#tarteaucitron #tarteaucitronClosePanel {
  background: #262B23;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
  z-index: 10000;
}

#tarteaucitron br {
  display: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 20px;
  text-align: left;
  width: 100%;
  background: #262B23;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #ffffff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 290px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
  line-height: normal;
  background: #262B23;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: #ffffff;
  margin: 0;
  overflow: hidden;
  padding: 15px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #ffffff;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 0px;
  text-align: left;
  width: 50%;
  font-family: "font";
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

@media screen and (max-width: 992px) {
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: 5px;
  }
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny {
  background: transparent;
  border: 2px solid #262B23;
  color: #262B23;
  font-family: "font";
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2rem;
  border-radius: 2.5rem;
  height: 4rem;
  width: 16rem;
  margin: 10px;
  cursor: pointer;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny:hover {
  background-color: #262B23;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny {
    height: 40px;
    width: 120px;
    border-radius: 25px;
    font-size: 12px;
  }
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected:hover {
  background: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background: red;
  color: #ffffff;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background: #1B870B;
  color: #ffffff;
  border: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow:hover {
  background: #1B870B;
  color: #ffffff;
  border: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background: red;
  color: #ffffff;
  border: none;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny:hover {
  color: #ffffff;
  background: red;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
  color: #262B23 !important;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 30px !important;
  margin: -10px auto 20px !important;
  font-size: 12px !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #262B23;
  line-height: 1.4 !important;
  background-color: #262B23 !important;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronLine .tarteaucitronAllow,
.tarteaucitronLine .tarteaucitronDeny {
  opacity: 1;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}

.c-formulaire {
  position: relative;
  overflow: hidden;
  /*************************************************
    // FORMULAIRE / VALIDATION
    *************************************************/
}

@media screen and (min-width: 992px) {
  .c-formulaire_row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
}

.c-formulaire_row:last-child {
  margin-top: 3.7037vh;
}

.c-formulaire_field {
  position: relative;
}

.c-formulaire_field.robot {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

@media screen and (max-width: 992px) {
  .c-formulaire_field {
    margin-top: 20px;
  }
}

.c-formulaire_field + .c-formulaire_field {
  margin-top: 1.85185vh;
}

.c-formulaire_field_label {
  display: block;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 1;
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 991px) {
  .c-formulaire_field_label {
    font-size: calc(0.21529vw + 11.86652px);
  }
}

@media (min-width: 1920px) {
  .c-formulaire_field_label {
    font-size: 16px;
  }
}

@media (min-width: 320px) {
  .c-formulaire_field_label {
    font-size: calc(1.90476vw + 7.90476px);
  }
}

@media (min-width: 425px) {
  .c-formulaire_field_label {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .c-formulaire_field_label {
    margin-bottom: 10px;
  }
}

.c-formulaire_field .form-radio {
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
}

.c-formulaire_field .form-radio input[type=radio],
.c-formulaire_field .form-radio input[type=checkbox] {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.c-formulaire_field .form-radio label {
  width: 23.44vw;
  display: inline-block;
  font-family: "font";
  font-size: 11px;
  line-height: 1.4;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  margin: 0;
}

@media (min-width: 991px) {
  .c-formulaire_field .form-radio label {
    font-size: calc(0.21529vw + 8.86652px);
  }
}

@media (min-width: 1920px) {
  .c-formulaire_field .form-radio label {
    font-size: 13px;
  }
}

@media screen and (max-width: 992px) {
  .c-formulaire_field .form-radio label {
    font-size: 13px;
    width: 100%;
  }
}

.c-formulaire_field .form-radio label:after {
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 1px #B79875;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  transition: all .2s ease;
}

.c-formulaire_field .form-radio label:before {
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #B79875;
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  z-index: 2;
  margin: auto;
  transition: all .2s ease;
  opacity: 0;
}

.c-formulaire_field .form-radio input[type=radio]:checked + label:before,
.c-formulaire_field .form-radio input[type=checkbox]:checked + label:before {
  opacity: 1;
}

.c-formulaire_field ::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-formulaire_field ::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-formulaire_field ::-ms-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-formulaire_field ::-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-formulaire_field :-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-formulaire_field input[type=tel],
.c-formulaire_field input[type=email],
.c-formulaire_field input[type=text],
.c-formulaire_field textarea {
  width: 100%;
  transition: all .5s ease;
  border: solid 1px #B79875;
  color: #ffffff;
  font-size: 14px;
  padding: 0 15px;
  border-radius: 25px;
  height: 50px;
  font-size: 13px;
  line-height: 1;
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 991px) {
  .c-formulaire_field input[type=tel],
  .c-formulaire_field input[type=email],
  .c-formulaire_field input[type=text],
  .c-formulaire_field textarea {
    font-size: calc(0.32293vw + 9.79978px);
  }
}

@media (min-width: 1920px) {
  .c-formulaire_field input[type=tel],
  .c-formulaire_field input[type=email],
  .c-formulaire_field input[type=text],
  .c-formulaire_field textarea {
    font-size: 16px;
  }
}

@media (min-width: 320px) {
  .c-formulaire_field input[type=tel],
  .c-formulaire_field input[type=email],
  .c-formulaire_field input[type=text],
  .c-formulaire_field textarea {
    font-size: calc(1.90476vw + 7.90476px);
  }
}

@media (min-width: 425px) {
  .c-formulaire_field input[type=tel],
  .c-formulaire_field input[type=email],
  .c-formulaire_field input[type=text],
  .c-formulaire_field textarea {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .c-formulaire_field input[type=tel],
  .c-formulaire_field input[type=email],
  .c-formulaire_field input[type=text],
  .c-formulaire_field textarea {
    height: 5rem;
    border-radius: 2.5rem;
    padding: 0 2rem;
  }
}

.c-formulaire_field textarea {
  padding-top: 10px;
  height: 180px;
}

@media (min-width: 992px) {
  .c-formulaire_field textarea {
    height: calc(21rem + 30px);
    padding-top: 15px;
  }
}

.c-formulaire_field .dropdown-toggle {
  display: block;
  width: 100%;
  position: relative;
}

.c-formulaire_field .dropdown-toggle .dropdown-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
  width: 100%;
  vertical-align: middle;
  transition: all .5s ease 0s;
  border: solid 1px #ffffff;
  color: #ffffff;
  font-size: 14px;
  height: 8rem;
  max-height: 60px;
  padding: 0 42px 0 15px;
  position: relative;
}

@media (min-width: 992px) {
  .c-formulaire_field .dropdown-toggle .dropdown-toggle-btn {
    font-size: 16px;
  }
}

.c-formulaire_field .dropdown-toggle .dropdown-toggle-btn:focus {
  color: #ffffff;
  background: #ffffff;
  border-color: #ffffff;
}

.c-formulaire_field .dropdown-toggle .dropdown-toggle-btn .icon-cursor {
  display: inline-flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}

.c-formulaire_field .dropdown-toggle ul.dropdown {
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;
  overflow: hidden;
  max-height: 0;
  transition: all .5s ease;
  font-size: 14px;
}

@media (min-width: 992px) {
  .c-formulaire_field .dropdown-toggle ul.dropdown {
    font-size: 16px;
  }
}

.c-formulaire_field .dropdown-toggle ul.dropdown li {
  max-height: 0;
  padding: 0 15px;
  line-height: 50px;
  transition: all .5s ease;
  color: #ffffff;
}

.c-formulaire_field .dropdown-toggle ul.dropdown li a:hover {
  opacity: .5;
}

.c-formulaire_field .dropdown-toggle.open a.toggle {
  transition-delay: 0s;
}

.c-formulaire_field .dropdown-toggle.open ul.dropdown {
  max-height: 300px;
  overflow: auto;
  transition: all .5s ease 0s;
}

.c-formulaire_field .dropdown-toggle.open ul.dropdown li {
  max-height: 50px;
  transition-delay: 0s;
}

.c-formulaire_field::after {
  margin-top: 0;
  display: block;
  content: "";
  height: 0;
  opacity: 0;
  transition: 0.5s all ease-out;
}

.c-formulaire_field.error::after {
  height: 15px;
  margin: 5px;
  content: attr(data-message);
  color: red;
  opacity: 1;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.05em;
  top: 0;
  text-align: left;
}

.c-formulaire_send {
  margin-top: 2rem;
}

.c-formulaire_send .bouton {
  min-width: 13.88889vh;
}

.c-formulaire_send .h-captcha {
  margin: 2rem 0;
}

@media screen and (max-width: 992px) {
  .c-formulaire_send {
    margin-top: 40px;
    justify-content: center;
  }
}

.c-formulaire_file input[type=file] {
  opacity: 0;
  width: 0;
  height: 0;
  appearance: none;
  position: absolute;
}

.c-formulaire_file label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
}

@media (min-width: 760px) {
  .c-formulaire_file label {
    font-size: 15px;
    padding: 0 25px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .c-formulaire_file label {
    font-size: 16px;
    padding: 0 30px;
    height: 60px;
  }
}

.c-formulaire_file label:after, .c-formulaire_file label:before {
  content: normal;
  display: none;
}

.c-formulaire_file .name-file {
  display: block;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.c-formulaire_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(38, 43, 35, 0.8);
  width: auto;
  transition: all .6s ease;
  cursor: pointer;
}

@media (max-width: 992px) and (min-width: 760px) {
  .c-formulaire_overlay {
    width: calc(100% - 30px);
    margin: auto;
  }
}

.c-formulaire_overlay_text {
  position: absolute;
  top: 40%;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 100;
  margin: auto;
  width: 100%;
  height: auto;
  min-height: auto;
  display: block;
  opacity: 0;
  transform: translateY(-50%);
  transition: all .5s ease;
}

@media (min-width: 760px) {
  .c-formulaire_overlay_text {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .c-formulaire_overlay_text {
    width: 60%;
  }
}

.c-formulaire_overlay_text p {
  line-height: 1.33;
  text-align: center;
  color: #2E3429;
  font-weight: 600;
  font-size: 16px;
  padding: 5vw 30px;
  background: white;
}

@media (min-width: 992px) {
  .c-formulaire_overlay_text p {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .c-formulaire_overlay_text p {
    font-size: 20px;
  }
}

@media (min-width: 1500px) {
  .c-formulaire_overlay_text p {
    font-size: 24px;
  }
}

.c-formulaire_overlay_text p .icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: auto;
}

.c-formulaire_overlay_text.active {
  opacity: 1;
  top: 50%;
}

.c-formulaire_overlay.close {
  opacity: 0;
}

.c-formulaire_overlay.close .form-msg {
  opacity: 0;
  top: 60%;
}

.c-formulaire_overlay.under {
  z-index: -1;
}

#recaptcha {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

@media screen and (max-width: 992px) {
  #recaptcha {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

[js-collapse-wrapper] > [js-collapse-content] {
  max-height: 0;
  opacity: 0;
  transition: opacity 0.3s ease, max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

[js-collapse-wrapper] > [js-collapse-content].open {
  max-height: 600vh;
  opacity: 1;
  transition: opacity .3s ease, max-height 1s ease-in-out;
}

.flatpickr-calendar {
  font-family: "font";
  background-color: #F3E1D1 !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  display: none !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #262B23 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #262B23 !important;
}

.flatpickr-day.today {
  border-color: #262B23 !important;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  background-color: #262B23 !important;
}

.flatpickr-months {
  padding: 10px;
  align-items: center;
}

.flatpickr-current-month {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;
  font-family: 'icomoon' !important;
  width: 21px !important;
  height: 21px !important;
  padding: 0 !important;
  transform: rotate(90deg);
}

.flatpickr-months .flatpickr-prev-month::before,
.flatpickr-months .flatpickr-next-month::before {
  display: block;
  content: "\e902";
  font-size: 20px;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  display: none;
}

.flatpickr-prev-month {
  transform: rotate(-180deg) !important;
}

.flatpickr-next-month {
  transform: rotate(0deg) !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #262B23 !important;
}

.c-bookBar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262B23;
  min-width: 93.51852vh;
  padding: 0 3.5rem;
  height: 10.5rem;
  border-radius: 5rem;
  z-index: 10;
}

@media screen and (max-width: 992px) {
  .c-bookBar {
    height: auto;
    flex-direction: column;
    min-width: 0;
    width: 100%;
    border-radius: 30px;
    padding: 35px 6.410256410256411vw;
  }
}

.c-bookBar_content {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .c-bookBar_content {
    flex-direction: column;
    width: 100%;
  }
}

.c-bookBar_field {
  position: relative;
  margin-right: 1.85185vh;
}

@media screen and (max-width: 992px) {
  .c-bookBar_field {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.c-bookBar_field:last-child {
  margin-right: 12.5vh;
}

@media screen and (max-width: 992px) {
  .c-bookBar_field:last-child {
    margin-right: 0;
  }
}

.c-bookBar_field ::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-bookBar_field ::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-bookBar_field ::-ms-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-bookBar_field ::-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-bookBar_field :-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.c-bookBar_field input[type=tel],
.c-bookBar_field input[type=email],
.c-bookBar_field input[type=text] {
  position: relative;
  font-family: "font";
  border: 1px solid #906A41;
  border-radius: 2.5rem;
  height: 5rem;
  width: 20rem;
  cursor: pointer;
  color: #ffffff;
  padding: 0 1.85185vh;
  z-index: 1;
  font-size: 12px;
  line-height: 1;
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 991px) {
  .c-bookBar_field input[type=tel],
  .c-bookBar_field input[type=email],
  .c-bookBar_field input[type=text] {
    font-size: calc(0.64586vw + 5.59957px);
  }
}

@media (min-width: 1920px) {
  .c-bookBar_field input[type=tel],
  .c-bookBar_field input[type=email],
  .c-bookBar_field input[type=text] {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .c-bookBar_field input[type=tel],
  .c-bookBar_field input[type=email],
  .c-bookBar_field input[type=text] {
    font-size: calc(2.85714vw + 4.85714px);
  }
}

@media (min-width: 425px) {
  .c-bookBar_field input[type=tel],
  .c-bookBar_field input[type=email],
  .c-bookBar_field input[type=text] {
    font-size: 17px;
  }
}

@media screen and (max-width: 992px) {
  .c-bookBar_field input[type=tel],
  .c-bookBar_field input[type=email],
  .c-bookBar_field input[type=text] {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    padding: 0 20px;
  }
}

.c-bookBar_field .icon-cursor {
  display: inline-flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  right: 1.85185vh;
  transform: translateY(-50%);
  z-index: 0;
  color: #906A41;
}

@media screen and (max-width: 992px) {
  .c-bookBar_field .icon-cursor {
    right: 20px;
    display: inline-flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
  }
}

.c-bookBar_field .dropdown-toggle {
  display: block;
  width: 100%;
  position: relative;
}

.c-bookBar_field .dropdown-toggle .dropdown-toggle-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
  height: 5rem;
  width: 27rem;
  font-family: "font";
  font-size: 12px;
  line-height: 1;
  font-size: 14px;
  line-height: 1;
  border: 1px solid #906A41;
  border-radius: 2.5rem;
  cursor: pointer;
  color: #ffffff;
  padding: 0 1.85185vh;
  z-index: 1;
  transition: all .5s ease 0s;
}

@media (min-width: 991px) {
  .c-bookBar_field .dropdown-toggle .dropdown-toggle-btn {
    font-size: calc(0.64586vw + 5.59957px);
  }
}

@media (min-width: 1920px) {
  .c-bookBar_field .dropdown-toggle .dropdown-toggle-btn {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .c-bookBar_field .dropdown-toggle .dropdown-toggle-btn {
    font-size: calc(2.85714vw + 4.85714px);
  }
}

@media (min-width: 425px) {
  .c-bookBar_field .dropdown-toggle .dropdown-toggle-btn {
    font-size: 17px;
  }
}

@media screen and (max-width: 992px) {
  .c-bookBar_field .dropdown-toggle .dropdown-toggle-btn {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    padding: 0 20px;
  }
}

.c-bookBar_field .dropdown-toggle .dropdown-toggle-btn .icon-cursor {
  display: inline-flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  color: #906A41;
}

@media screen and (max-width: 992px) {
  .c-bookBar_field .dropdown-toggle .dropdown-toggle-btn .icon-cursor {
    right: 20px;
    display: inline-flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
  }
}

.c-bookBar_field .dropdown-toggle ul.dropdown {
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 2;
  background: #262B23;
  overflow: hidden;
  max-height: 0;
  border-radius: 0 0 2.5rem 2.5rem;
  transition: all .5s ease;
}

@media screen and (max-width: 992px) {
  .c-bookBar_field .dropdown-toggle ul.dropdown {
    border-radius: 0 0 25px 25px;
  }
}

.c-bookBar_field .dropdown-toggle ul.dropdown li {
  max-height: 0;
  opacity: 0;
  transform: translateY(-50%);
  transition: all .5s ease;
}

.c-bookBar_field .dropdown-toggle ul.dropdown li button {
  font-size: 12px;
  line-height: 1;
  font-size: 14px;
  line-height: 1;
  font-family: "font";
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  color: #ffffff;
}

@media (min-width: 991px) {
  .c-bookBar_field .dropdown-toggle ul.dropdown li button {
    font-size: calc(0.7535vw + 4.53283px);
  }
}

@media (min-width: 1920px) {
  .c-bookBar_field .dropdown-toggle ul.dropdown li button {
    font-size: 19px;
  }
}

@media (min-width: 320px) {
  .c-bookBar_field .dropdown-toggle ul.dropdown li button {
    font-size: calc(2.85714vw + 4.85714px);
  }
}

@media (min-width: 425px) {
  .c-bookBar_field .dropdown-toggle ul.dropdown li button {
    font-size: 17px;
  }
}

.c-bookBar_field .dropdown-toggle ul.dropdown li button:hover {
  background-color: #906A41;
}

.c-bookBar_field .dropdown-toggle.open .dropdown-toggle-btn {
  border-radius: 2.5rem 2.5rem 0 0;
  transition: all .5s ease 0s;
}

@media screen and (max-width: 992px) {
  .c-bookBar_field .dropdown-toggle.open .dropdown-toggle-btn {
    border-radius: 25px 25px 0 0;
  }
}

.c-bookBar_field .dropdown-toggle.open ul.dropdown {
  max-height: 500vh;
  overflow: auto;
  border-left: 1px solid #906A41;
  border-right: 1px solid #906A41;
  border-bottom: 1px solid #906A41;
  padding: 1rem 0;
  transition: all .5s ease 0s;
}

@media screen and (max-width: 992px) {
  .c-bookBar_field .dropdown-toggle.open ul.dropdown {
    padding: 20px 0;
  }
}

.c-bookBar_field .dropdown-toggle.open ul.dropdown li {
  opacity: 1;
  transform: translateY(0);
  max-height: 400vh;
  transition-delay: 0s;
}

.c-bookBar_field::after {
  display: block;
  content: 'Merci de renseigner ce champ';
  position: absolute;
  min-width: 21rem;
  bottom: calc(100% + 5px);
  left: 0;
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-family: "font";
  font-size: 15px;
  line-height: 1.4;
  font-size: 12px;
  line-height: 1;
  color: red;
  font-weight: 700;
  text-align: left;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 991px) {
  .c-bookBar_field::after {
    font-size: calc(-0.10764vw + 16.06674px);
  }
}

@media (min-width: 1920px) {
  .c-bookBar_field::after {
    font-size: 14px;
  }
}

@media (min-width: 320px) {
  .c-bookBar_field::after {
    font-size: calc(1.90476vw + 5.90476px);
  }
}

@media (min-width: 425px) {
  .c-bookBar_field::after {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .c-bookBar_field::after {
    padding: 10px 15px;
    border-radius: 20px;
    min-width: 0;
    text-align: center;
    width: auto;
  }
}

.c-bookBar_field.error::after {
  z-index: 10;
  opacity: 1;
  pointer-events: initial;
}

@media screen and (max-width: 992px) {
  .c-bookBar_send {
    width: 100%;
  }
}

.c-bookBar_send .bouton-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.42593vh;
  height: 5rem;
  border-radius: 2.5rem;
  color: #ffffff;
  font-family: "font";
  font-size: 14px;
  line-height: 1;
  font-size: 14px;
  line-height: 1;
  background-color: #906A41;
  transition: all .3s ease;
}

@media (min-width: 991px) {
  .c-bookBar_send .bouton-send {
    font-size: calc(0.43057vw + 9.73305px);
  }
}

@media (min-width: 1920px) {
  .c-bookBar_send .bouton-send {
    font-size: 18px;
  }
}

@media (min-width: 320px) {
  .c-bookBar_send .bouton-send {
    font-size: calc(2.85714vw + 4.85714px);
  }
}

@media (min-width: 425px) {
  .c-bookBar_send .bouton-send {
    font-size: 17px;
  }
}

@media screen and (max-width: 992px) {
  .c-bookBar_send .bouton-send {
    width: 100%;
    height: 50px;
    border-radius: 25px;
  }
}

.c-bookBar_send .bouton-send:hover {
  background-color: #ffffff;
  color: #B79875;
  transition: all .3s ease;
}

.bloc1 .h1 {
  margin-bottom: 3.7037vh;
}

@media screen and (max-width: 992px) {
  .bloc1 .h1 {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 1.27;
  }
}

@media screen and (max-width: 992px) and (min-width: 320px) {
  .bloc1 .h1 {
    font-size: calc(14.28571vw + -25.71429px);
  }
}

@media screen and (max-width: 992px) and (min-width: 390px) {
  .bloc1 .h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 992px) {
  .bloc1 .gate {
    margin: 45px 0 0;
  }
}

.bloc1 img {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .bloc1 .grid-container:not(:last-child) > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .bloc1 .grid-container:nth-child(1) div {
    justify-content: start;
  }
  .bloc1 .grid-container:nth-child(1) div p {
    padding-right: 12.03704vh;
  }
  .bloc1 .grid-container:nth-child(2) {
    z-index: 6;
  }
  .bloc1 .grid-container:nth-child(2) .image-figure {
    margin: -5.21vw 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 992px) {
  .bloc1 .grid-container:nth-child(2) .image-figure {
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .bloc1 .grid-container:nth-child(4) {
    margin-top: 14.35185vh;
  }
}

@media screen and (max-width: 992px) {
  .bloc1 .grid-container + .grid-container {
    margin-top: 85px;
  }
  .bloc1 .grid-container p {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 992px) {
  .bloc1.variante:nth-child(odd) .gate {
    margin-left: -15px;
  }
  .bloc1.variante p {
    padding-right: 13.88889vh;
  }
  .bloc1.variante:nth-child(even) .gate {
    margin-left: 15px;
  }
}

.bloc1.variante .bouton {
  margin-top: 3.51852vh;
  color: #262B23;
  min-width: 217px;
}

@media screen and (max-width: 992px) {
  .bloc1.variante .bouton {
    margin-top: 28px;
  }
}

.bloc1.variante .grid-container:nth-child(2) .image-figure {
  margin: 0;
}

@media screen and (min-width: 992px) {
  .bloc1.variante .grid-container:nth-child(2) .image-figure {
    transform: translateY(-12vw);
  }
}

@media screen and (max-width: 992px) {
  .bloc1.variante .grid-container:nth-child(2) {
    margin-bottom: 70px;
  }
}

.bloc1.variante .h3, .bloc1.variante .etapes .infos .info p, .etapes .infos .info .bloc1.variante p, .bloc1.variante .carte_content .carte_group .carte_product .price, .carte_content .carte_group .carte_product .bloc1.variante .price {
  margin-top: 11.75926vh;
}

.bloc1.variante .h3 + p, .bloc1.variante .etapes .infos .info p + p, .etapes .infos .info .bloc1.variante p + p, .bloc1.variante .carte_content .carte_group .carte_product .price + p, .carte_content .carte_group .carte_product .bloc1.variante .price + p {
  margin-top: 3.33333vh;
}

@media screen and (max-width: 992px) {
  .bloc1.variante .h3 + p, .bloc1.variante .etapes .infos .info p + p, .etapes .infos .info .bloc1.variante p + p, .bloc1.variante .carte_content .carte_group .carte_product .price + p, .carte_content .carte_group .carte_product .bloc1.variante .price + p {
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.bloc1.variante .h1 + .h3, .bloc1.variante .etapes .infos .info .h1 + p, .etapes .infos .info .bloc1.variante .h1 + p, .bloc1.variante .carte_content .carte_group .carte_product .h1 + .price, .carte_content .carte_group .carte_product .bloc1.variante .h1 + .price {
  margin-top: 0;
}

.bloc1.variante p {
  color: #2E3429;
}

.etapes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11.11111vh;
}

.etapes .dates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .etapes .dates {
    gap: 34px;
    width: unset;
  }
}

.etapes .dates::after {
  content: '';
  background-color: #B79875;
  width: 100%;
  height: 1px;
  position: absolute;
  z-index: -1;
}

.etapes .dates .date {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding: 10px 15px;
  padding-top: 13px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #2E3429;
  border: 1px solid #B79875;
  border-radius: 100vmax;
  color: #ffffff;
  transition: all .3s ease;
  font-size: 25px !important;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .etapes .dates .date {
    height: 50px;
    font-size: 25px !important;
  }
}

.etapes .dates .date.active {
  background-color: #B79875;
}

.etapes .dates .date:hover {
  cursor: pointer;
}

.etapes .infos {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.etapes .infos .info {
  display: flex;
  align-items: center;
  gap: 6.48148vh;
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease;
  height: 100%;
}

.etapes .infos .info.active {
  transition: all .3s ease .3s;
  opacity: 1;
  pointer-events: all;
}

.etapes .infos .info.active .number {
  transition: all .3s ease .3s;
  transform: translate(0px, 0px);
}

.etapes .infos .info:not(:first-child) {
  position: absolute;
}

@media screen and (max-width: 992px) {
  .etapes .infos .info {
    gap: 24px;
    flex-direction: column;
  }
  .etapes .infos .info p {
    text-align: center;
  }
}

.etapes .infos .info .number {
  transition: all .3s ease;
  font-size: 25px;
  transform: translate(-70px, 0px);
  font-size: 50px;
  line-height: 1.1;
  font-weight: 600;
  color: #B79875;
}

@media (min-width: 991px) {
  .etapes .infos .info .number {
    font-size: calc(3.22928vw + 17.99785px);
  }
}

@media (min-width: 1920px) {
  .etapes .infos .info .number {
    font-size: 80px;
  }
}

@media screen and (max-width: 992px) {
  .etapes .infos .info .number {
    font-size: 70px;
    transform: translate(0px, -40px);
  }
}

.etapes .infos .info p {
  max-width: 33.33333vh;
}

@media screen and (max-width: 992px) {
  .etapes .infos .info p {
    max-width: 100%;
  }
}

/*************************************************
// INCLUDE DES FONTS
*************************************************/
@font-face {
  font-family: 'font';
  src: url("../fonts/PPHatton-Regular.eot");
  src: url("../fonts/PPHatton-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PPHatton-Regular.woff2") format("woff2"), url("../fonts/PPHatton-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*************************************************
// STYLE DES FONTS
*************************************************/
.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

span.tarteaucitronH3, #tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3, .c-actu__title, .c-actu_similar__title, .c-article__title {
  font-family: "font";
  font-weight: normal;
  font-style: normal;
}

.mag_content_title,
.article_content_title, .mag_content_title_sub,
.article_content_title_sub, .c-articles--no-articles {
  font-family: "font";
  font-weight: 700;
  font-style: normal;
}

/*************************************************
// ICOMOON
*************************************************/
@keyframes height-100-0 {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes trans-opacity-top-100 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trans-opacity-bot-100 {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trans-opacity-left-100 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trans-opacity-right-100 {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rebond-start {
  from {
    opacity: 0;
    transform: scale(1);
  }
  33% {
    opacity: 1;
    transform: scale(1.15);
  }
  66% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.c-transition {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
}

.c-transition:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #262B23;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 100%;
  z-index: 1;
}

.c-transition:before {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #262B23;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 100%;
  z-index: 0;
}

.c-transition_wrapper {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.c-transition_wrapper_sigle {
  position: relative;
  width: 15vw;
  opacity: 0;
  z-index: 4;
  top: 0;
}

@media screen and (max-width: 992px) {
  .c-transition_wrapper_sigle {
    width: 180px;
    max-width: 80%;
  }
}

.c-transition_wrapper_sigle img {
  width: 100%;
  height: auto;
}

.c-transition.under {
  z-index: -999;
  opacity: 0;
}

.c-transition.first:after {
  background-color: #262B23;
  top: 0;
  right: 0;
  opacity: 1;
}

.c-transition.first:before {
  top: 0;
  opacity: 1;
  right: 0;
  background-color: #262B23;
}

.c-transition.first.anim .c-transition_wrapper_sigle {
  animation: rebond-start 1s linear forwards 0.2s;
}

.c-transition.first.remove {
  top: -100%;
  opacity: 0;
  transition: top 0.5s ease 0.9s, opacity 0.1s linear 1s;
}

.c-transition.first.remove:after {
  top: -100%;
  transition: top 0.8s ease;
}

.c-transition.first.remove:before {
  top: -100%;
  transition: top 0.8s ease;
}

.c-transition.first.remove .c-transition_wrapper_sigle {
  top: -100%;
  transition: top 0.7s ease 0.15s;
}

.parallax {
  transition: all 0.5s linear;
}

.parallax.speed2 {
  transition: all 0.6s linear;
}

.anim-opacity {
  position: relative;
  transition: all 1s;
}

.anim-opacity.masked {
  transition: none;
  opacity: 0;
}

.anim-bottom {
  position: relative;
  transition: all 1s;
}

.anim-bottom.masked {
  transition: none;
  transform: translateY(50%);
  opacity: 0;
}

.anim-right {
  position: relative;
  transition: all 1s;
}

.anim-right.masked {
  transition: none;
  transform: translateX(50%);
  opacity: 0;
}

.anim-left {
  position: relative;
  transition: all 1s;
}

.anim-left.masked {
  transition: none;
  transform: translateX(-50%);
  opacity: 0;
}

@media screen and (min-width: 992px) {
  .anim-delay--1 {
    transition-delay: 0.2s;
    z-index: 1;
  }
  .anim-delay--2 {
    transition-delay: 0.4s;
    z-index: 2;
  }
  .anim-delay--3 {
    transition-delay: 0.6s;
    z-index: 3;
  }
  .anim-delay--4 {
    transition-delay: 0.8s;
    z-index: 4;
  }
  .anim-delay--5 {
    transition-delay: 1s;
    z-index: 5;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?2iteac");
  src: url("../fonts/icomoon/icomoon.eot?2iteac#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?2iteac") format("truetype"), url("../fonts/icomoon/icomoon.woff?2iteac") format("woff"), url("../fonts/icomoon/icomoon.svg?2iteac#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\e900";
}

.icon-twitter:before {
  content: "\ea96";
}

.icon-pdf:before {
  content: "\eadf";
}

.icon-arrow:before {
  content: "\e902";
}

.icon-download:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-instagram:before {
  content: "\e905";
}

.icon-itineraire:before {
  content: "\e906";
}

.icon-linkedin:before {
  content: "\e907";
}

.icon-piece-jointe:before {
  content: "\e908";
}

.icon-refresh:before {
  content: "\e909";
}

.icon-tiktok:before {
  content: "\e90a";
}

.icon-triangle:before {
  content: "\e90b";
}

.icon-arrow-plain:before {
  content: "\e901";
}

.mag,
.article {
  background-color: #F3E1D1;
}

.mag .p-nav,
.article .p-nav {
  color: #2E3429;
  background-color: #F3E1D1;
}

.mag .p-nav .bouton,
.article .p-nav .bouton {
  color: #2E3429;
}

.mag .p-nav .logo,
.article .p-nav .logo {
  opacity: 1;
  width: 10.18519vh;
}

@media screen and (max-width: 992px) {
  .mag .p-nav .logo,
  .article .p-nav .logo {
    width: 110px;
  }
}

.mag #section-top,
.article #section-top {
  overflow: hidden;
  position: relative;
}

.mag_content,
.article_content {
  background-color: #F3E1D1;
  position: relative;
  padding-top: 15.625vw;
  padding-bottom: 10rem;
}

@media screen and (max-width: 992px) {
  .mag_content,
  .article_content {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}

.mag_content_title,
.article_content_title {
  margin-bottom: 5rem;
  font-size: 4.5rem;
  font-family: "font";
  color: #262B23;
}

.mag_content_title_sub,
.article_content_title_sub {
  display: block;
  margin-top: 10px;
  font-size: 50%;
  color: #7a8285;
}

.c-form-sort {
  width: 100%;
  position: fixed;
  top: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  left: 0;
  background-color: #F3E1D1;
  z-index: 102;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-form-sort {
    top: 85px;
  }
}

@media screen and (max-width: 992px) {
  .c-form-sort .simple-container {
    padding: 0;
  }
}

.c-form-sort_selectors {
  overflow-x: auto;
  overflow-y: visible;
  width: auto;
  height: auto;
}

@media screen and (max-width: 992px) {
  .c-form-sort_selectors {
    justify-content: space-between;
    width: 100%;
  }
}

.c-form-sort_select {
  width: 100%;
  position: relative;
  margin-top: 5rem;
}

@media (min-width: 760px) {
  .c-form-sort_select {
    width: auto;
    margin-top: 0;
  }
}

.c-form-sort_select_title {
  position: relative;
  text-align: right;
  margin-bottom: 10px;
  font-size: 12px;
}

@media (min-width: 760px) {
  .c-form-sort_select_title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: left;
    z-index: 1;
    transform: translateY(-120%);
  }
}

.c-form-sort__search {
  width: 100%;
  border: solid 1px #262B23;
  color: #262B23;
  height: 35px;
  margin-bottom: 15px;
  padding: 0 15px;
  font-size: 14px;
}

@media (min-width: 760px) {
  .c-form-sort__search {
    width: 250px;
  }
}

.c-form-sort__search::-webkit-input-placeholder {
  color: #262B23;
  opacity: 1;
}

.c-form-sort__search::-moz-placeholder {
  color: #262B23;
  opacity: 1;
}

.c-form-sort__search::-ms-placeholder {
  color: #262B23;
  opacity: 1;
}

.c-form-sort__search::-ms-input-placeholder {
  color: #262B23;
  opacity: 1;
}

.c-form-sort__search:-ms-input-placeholder {
  color: #262B23;
  opacity: 1;
}

.c-form-sort__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "font";
  font-weight: 500;
  padding: 0 2rem;
  height: 6rem;
  border-radius: 3rem;
  color: #262B23;
  border: 1px solid #906A41;
  background-color: transparent;
  font-size: 1.8rem;
  margin-right: 2rem;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button {
    font-size: 18px;
    height: 40px;
    width: 50%;
    padding: 0 10px;
    border: none;
  }
}

@media screen and (max-width: 340px) {
  .c-form-sort__button {
    font-size: 14px;
  }
}

.c-form-sort__button.button-home {
  display: none;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button:nth-child(2) {
    width: 30%;
  }
}

@media screen and (max-width: 992px) {
  .c-form-sort__button:last-child:not(.c-form-sort__button--small) {
    width: 20%;
  }
}

.c-form-sort__button .text {
  display: inline-block;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .text {
    margin-right: 10px;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .reset {
    display: none;
  }
}

.c-form-sort__button .icons {
  margin-left: 10px;
}

.c-form-sort__button .icons.invers {
  margin-left: 0;
  margin-right: 10px;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .icons.invers {
    margin-right: 0;
  }
}

.c-form-sort__button .icon-triangle {
  display: inline-flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 10px;
  color: #262B23;
  transform: rotate(0);
  transition: all .3s ease-out;
}

@media screen and (max-width: 1200px) {
  .c-form-sort__button .icon-triangle {
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
  }
}

.c-form-sort__button .icon-mag-reinit {
  display: inline-flex;
  font-size: 1.7rem;
  align-items: center;
  justify-content: center;
  width: 1.7rem;
  height: 1.7rem;
  transform: rotate(-45deg) scaleX(-1);
  transition: all .3s ease-out;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button .icon-mag-reinit {
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
  }
}

.c-form-sort__button:after, .c-form-sort__button:before {
  content: normal;
  display: none;
}

.c-form-sort__button--small {
  position: relative;
  min-width: 0;
  font-family: "font";
  font-weight: 400;
  text-transform: none;
  font-size: 1.8rem;
  border: 1px solid #B79875;
  background: #B79875;
  color: #262B23;
  height: 3.5rem;
  border-radius: 3.5rem;
  padding: 0 1.8rem;
  margin: 1rem;
}

@media screen and (max-width: 992px) {
  .c-form-sort__button--small {
    font-size: 18px;
    height: 30px;
    margin: 0;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 15px;
    width: auto !important;
  }
}

.c-form-sort__button--small:last-child {
  margin-right: 0;
}

.c-form-sort__button--small:hover {
  background: transparent !important;
  color: #262B23 !important;
}

.c-form-sort__button--small.active {
  background: transparent !important;
  color: #262B23 !important;
}

@media screen and (min-width: 992px) {
  .c-form-sort__button:hover, .c-form-sort__button.active {
    background: #906A41;
    color: #ffffff;
  }
  .c-form-sort__button:hover .icon-triangle, .c-form-sort__button.active .icon-triangle {
    color: #ffffff;
  }
}

@media screen and (min-width: 992px) {
  .c-form-sort__button.active {
    background: #906A41;
  }
  .c-form-sort__button.active .icon-triangle {
    color: #ffffff;
    transform: rotate(-180deg);
  }
}

.c-form-sort_list {
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;
  opacity: 0;
  transition: all .3s ease-out;
  background: #F3E1D1;
  padding: 1.5rem 0;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .c-form-sort_list {
    padding: 15px;
  }
}

.c-form-sort_list.active {
  opacity: 1;
  visibility: visible;
}

.c-form-sort_list_items {
  width: auto;
}

.c-form-sort_list_notags {
  font-size: 12px;
  margin: 10px 0;
  color: #ffffff;
}

.c-actu {
  position: relative;
  padding-bottom: 5rem !important;
}

@media screen and (max-width: 992px) {
  .c-actu {
    padding-bottom: 50px !important;
  }
}

.c-actu .bouton {
  color: #2E3429;
}

.c-actu__cover {
  position: relative;
  overflow: hidden;
  margin-top: 4vw;
  margin-bottom: 6rem;
}

@media screen and (max-width: 992px) {
  .c-actu__cover {
    margin-bottom: 50px;
    margin-top: 0;
  }
}

.c-actu__cover_text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 4rem;
}

@media screen and (max-width: 992px) {
  .c-actu__cover_text {
    display: block;
    margin-bottom: 40px;
  }
}

.c-actu__cover_img {
  width: 100%;
  margin-top: 5vw;
}

@media screen and (max-width: 992px) {
  .c-actu__cover_img {
    margin-top: 40px;
  }
}

.c-actu__cover_img img {
  width: 100%;
  height: auto;
}

.c-actu__title {
  margin: 0;
  margin-bottom: 0;
  color: #2E3429;
  font-size: 4.5rem;
}

@media screen and (max-width: 992px) {
  .c-actu__title {
    width: 100%;
    font-size: 40px;
    margin-bottom: 40px;
  }
}

.c-actu__date {
  display: block;
  font-size: 16px;
  font-family: "font";
  font-weight: 500;
  color: #906A41;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .c-actu__date {
    font-size: 2rem;
    margin-top: 0rem;
    margin-bottom: 4rem;
  }
}

.c-actu__tags {
  margin-top: 15px;
}

.c-actu__tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 0 3rem;
  font-family: "font";
  font-weight: 500;
  font-size: 1.6rem;
  border: 1px solid #906A41;
  color: #2E3429;
  margin-right: 1rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-actu__tag {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    height: 40px;
    border-radius: 20px;
    padding: 0 20px;
  }
}

.c-actu__category {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 0 3rem;
  font-family: "font";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.6rem;
  border: 1px solid #2E3429;
  color: #2E3429;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: all .3s ease;
}

@media screen and (max-width: 992px) {
  .c-actu__category {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    height: 40px;
    padding: 0 10px;
  }
}

.c-actu__preview {
  font-size: 16px;
  color: #2E3429;
  margin-bottom: 50px;
  font-style: italic;
}

.c-actu__preview br {
  display: none !important;
}

.c-actu__preview p {
  color: #2E3429;
}

@media (min-width: 992px) {
  .c-actu__preview {
    font-size: 2.2rem;
    margin-bottom: 6rem;
  }
}

.c-actu__subtitle {
  font-size: 30px;
  color: #906A41;
  margin: 0;
  font-family: "font";
  font-weight: 700;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .c-actu__subtitle {
    font-size: 3.5rem;
    margin-bottom: 6rem;
  }
}

.c-actu__text {
  font-size: 16px;
  margin-bottom: 50px;
  line-height: 1.6;
  color: #2E3429;
}

@media (min-width: 992px) {
  .c-actu__text {
    font-size: 1.8rem;
    margin-bottom: 6rem;
  }
}

.c-actu__text strong {
  font-weight: 700;
}

.c-actu__text p,
.c-actu__text strong,
.c-actu__text ul,
.c-actu__text li {
  color: #2E3429;
}

.c-actu__text ul li {
  padding-left: 30px;
  list-style: inside;
}

.c-actu__text br {
  display: block !important;
}

.c-actu__text a {
  text-decoration: underline;
  color: #2E3429;
  font-weight: 700;
}

.c-actu__image {
  margin-bottom: 50px;
}

@media screen and (min-width: 992px) {
  .c-actu__image {
    margin-bottom: 6rem;
  }
}

.c-actu__image img {
  width: 100%;
  height: auto;
}

.c-actu__video {
  margin-bottom: 50px;
  padding-bottom: 58%;
  position: relative;
}

@media screen and (min-width: 992px) {
  .c-actu__video {
    margin-bottom: 6rem;
  }
}

.c-actu__video iframe {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.c-actu__button {
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (min-width: 992px) {
  .c-actu__button {
    margin-bottom: 6rem;
  }
}

.c-actu__cta {
  background-color: #2E3429;
  margin-bottom: 6rem;
  display: flex;
}

@media screen and (max-width: 992px) {
  .c-actu__cta {
    margin-bottom: 50px;
    display: block;
  }
}

.c-actu__cta_img {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

@media (min-width: 760px) {
  .c-actu__cta_img {
    width: 25.520833333333332vw;
    overflow: hidden;
    position: relative;
    padding: 0;
    margin-bottom: 0;
  }
}

.c-actu__cta_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-actu__cta_text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  font-family: "font";
  font-weight: 400;
  line-height: 1.4;
  color: #ffffff;
  padding: 25px;
  font-size: 16px;
}

@media (min-width: 760px) {
  .c-actu__cta_text {
    width: calc(100% - 25.520833333333332vw);
    padding: 4rem;
    font-size: 1.8rem;
  }
}

.c-actu__cta__title {
  font-size: 3rem;
  display: inline-block;
  margin-bottom: 4rem;
  color: #906A41;
}

@media screen and (max-width: 992px) {
  .c-actu__cta__title {
    font-size: 20px;
  }
}

.c-actu__cta__content {
  display: block;
  margin-bottom: 4rem;
}

@media screen and (max-width: 992px) {
  .c-actu__cta__content {
    margin-bottom: 30px;
  }
}

.c-actu__cta__button {
  text-align: right;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .c-actu__cta__button {
    text-align: center;
  }
}

.c-actu__cta__button .bouton {
  color: #ffffff;
}

.c-actu__pdf {
  margin-bottom: 6rem;
  padding-top: 3rem;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .c-actu__pdf {
    margin-bottom: 50px;
  }
}

.c-actu__pdf_title {
  display: inline-block;
  color: #906A41;
  font-weight: 900;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .c-actu__pdf_title {
    font-size: 18px;
  }
}

.c-actu__pdf_button .icon-download {
  display: inline-flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.c-actu__html-element {
  margin-bottom: 6rem;
}

@media screen and (max-width: 992px) {
  .c-actu__html-element {
    margin-bottom: 50px;
  }
}

.c-actu__gallery {
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 6rem;
}

@media screen and (max-width: 992px) {
  .c-actu__gallery {
    overflow: visible;
  }
}

.c-actu__gallery .slider-prev,
.c-actu__gallery .slider-next {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background: #2E3429;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3rem;
  right: auto;
  z-index: 5;
  left: 4rem;
  margin: auto;
  font-size: 0;
  transform: rotate(90deg);
}

@media screen and (max-width: 992px) {
  .c-actu__gallery .slider-prev,
  .c-actu__gallery .slider-next {
    width: 40px;
    height: 40px;
    left: -20px;
  }
}

.c-actu__gallery .slider-prev:before,
.c-actu__gallery .slider-next:before {
  display: none;
}

.c-actu__gallery .slider-prev:after,
.c-actu__gallery .slider-next:after {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  display: inline-flex;
  font-size: 2.2rem;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  color: #F3E1D1;
  display: block;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.c-actu__gallery .slider-next {
  right: 4rem;
  left: auto;
  transform: none;
  transform: rotate(-90deg);
}

@media screen and (max-width: 992px) {
  .c-actu__gallery .slider-next {
    right: -20px;
    left: auto;
  }
}

.c-actu__gallery .swiper-slide .wrapper {
  width: 100%;
  padding-bottom: 70%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.c-actu__gallery .swiper-slide .wrapper img {
  position: absolute;
  height: 100%;
  width: auto;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.c-actu__social {
  margin-top: 8rem;
}

@media screen and (max-width: 992px) {
  .c-actu__social {
    width: 100%;
    margin-top: 80px;
  }
}

.c-actu__social p {
  font-weight: 700;
  color: #906A41;
}

.c-actu__social_links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.c-actu__social_links .bt-share {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #2E3429;
  border-radius: 100%;
  position: relative;
}

@media screen and (max-width: 992px) {
  .c-actu__social_links .bt-share {
    width: 40px;
    height: 40px;
  }
}

.c-actu__social_links .bt-share [class^="icon-"] {
  display: inline-flex;
  font-size: 2.2rem;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
}

@media screen and (max-width: 992px) {
  .c-actu__social_links .bt-share [class^="icon-"] {
    display: inline-flex;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
  }
}

.c-actu__social_links .bt-share + .bt-share {
  margin-left: 20px;
}

@media (min-width: 992px) {
  .c-actu__social_links .bt-share + .bt-share {
    margin-left: 2rem;
  }
}

.c-actu__social_links .bt-share:hover {
  opacity: 1 !important;
  background-color: #2E3429;
  color: #ffffff;
  transition: all .3s ease;
}

.c-actu__social_links:hover .bt-share {
  opacity: .5;
}

.c-actu_similar {
  padding-top: 7vw;
  padding-bottom: 7vw;
}

@media screen and (max-width: 992px) {
  .c-actu_similar {
    padding-top: 120px;
  }
}

.c-actu_similar_head {
  margin-bottom: 5.2vw;
}

.c-actu_similar__title {
  color: #906A41;
  font-size: 30px;
  line-height: 1.2;
}

@media (min-width: 991px) {
  .c-actu_similar__title {
    font-size: calc(1.07643vw + 19.33262px);
  }
}

@media (min-width: 1920px) {
  .c-actu_similar__title {
    font-size: 40px;
  }
}

.c-articles_container {
  margin: 0 -15px;
  width: auto;
}

.c-articles--no-articles {
  text-align: center;
  font-size: 14px;
  margin-top: 5rem;
}

@media (min-width: 760px) {
  .c-articles--no-articles {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .c-articles--no-articles {
    font-size: 18px;
  }
}

.c-articles_see-more {
  width: 100%;
  text-align: center;
  margin-top: 4rem;
}

@media screen and (max-width: 992px) {
  .c-articles_see-more {
    margin-top: 0;
  }
}

.c-articles_see-more .bouton {
  color: #2E3429;
}

.c-articles_see-more.hidden {
  display: none;
}

.c-article__img {
  width: 100%;
  height: 15.625vw;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .c-article__img {
    height: 58.666666666666664vw;
  }
}

.c-article__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1);
  transition: all .3s ease;
}

.c-article__infos {
  background-color: #2E3429;
  position: relative;
  height: calc(100% - 15.625vw);
  padding: 2.6041666666666665vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .c-article__infos {
    height: auto;
    padding: 30px 25px;
    padding-bottom: 45px;
  }
}

@media screen and (min-width: 992px) {
  .c-article__infos div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.c-article__infos .arrow {
  position: absolute;
  bottom: 1.5625vw;
  right: 1.5625vw;
  z-index: 3;
}

@media screen and (max-width: 992px) {
  .c-article__infos .arrow {
    bottom: 20px;
    right: 20px;
  }
}

.c-article__infos .arrow .icon-arrow {
  display: inline-flex;
  font-size: 1.5625vw;
  align-items: center;
  justify-content: center;
  width: 1.5625vw;
  height: 1.5625vw;
  color: #262B23;
}

@media screen and (max-width: 992px) {
  .c-article__infos .arrow .icon-arrow {
    display: inline-flex;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
  }
}

.c-article__category {
  display: block;
  font-family: "font";
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .c-article__category {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.c-article__title {
  font-size: 22px;
  line-height: 1.2;
  color: #B79875;
  margin: 0;
  transition: all .25s ease-out;
}

@media (min-width: 991px) {
  .c-article__title {
    font-size: calc(1.07643vw + 11.33262px);
  }
}

@media (min-width: 1920px) {
  .c-article__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 992px) {
  .c-article__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 340px) {
  .c-article__title {
    font-size: 22px;
  }
}

.c-article__date {
  display: inline-block;
  font-size: 1.6rem;
  font-family: "font";
  font-weight: 400;
  color: #B79875;
  transition: all .25s ease-out;
}

@media screen and (max-width: 992px) {
  .c-article__date {
    font-size: 20px;
    margin-bottom: 25px;
  }
}

.c-article__preview {
  display: inline-block;
  font-size: 2rem;
  font-family: "font";
  font-weight: 400;
  color: #ffffff;
  margin-top: 2.31481vh;
  margin-bottom: 2.31481vh;
  transition: all .25s ease-out;
}

@media screen and (max-width: 992px) {
  .c-article__preview {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.c-article--card {
  position: relative;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  min-height: 26.5625vw;
  width: calc(100% - 30px);
  transition: all .25s ease-out .25s, width .25s ease-out 0s, padding .25s ease-out 0s, max-height .25s ease-out 0s;
  opacity: 1;
  transform: scale(1);
  max-height: 100vh;
  overflow: hidden;
  z-index: 7;
}

@media (min-width: 992px) {
  .c-article--card {
    width: calc(33.333% - 30px);
  }
}

.c-article--card.inactive {
  opacity: 0;
  transform: scale(0.5);
  width: 0;
  max-height: 0;
  margin: 0;
  padding: 0;
  visibility: hidden;
  transition: all .3s ease-out 0s, width .1s linear .25s, padding .1s linear .25s, max-height .1s linear .25s;
}

.c-article--card a {
  display: block;
  height: 100%;
}

.c-article--card:hover .c-article__infos .arrow {
  animation: arrow-mag 1s ease infinite;
}

.c-article--featured {
  width: 100%;
  background-color: #262B23;
}

@media (min-width: 992px) {
  .c-article--featured {
    width: calc((33.333% - 15px) * 2);
  }
}

@media screen and (min-width: 992px) {
  .c-article--featured a {
    display: flex;
  }
}

@media (min-width: 760px) {
  .c-article--featured .c-article__img {
    height: 100%;
    width: 35.9375vw;
  }
}

.c-article--featured .c-article__infos {
  background-color: #262B23;
}

@media (min-width: 760px) {
  .c-article--featured .c-article__infos {
    width: calc(100% - 35.9375vw);
    height: 100%;
  }
}

.c-article--featured .c-article__infos .arrow .icon-arrow {
  color: #262B23;
}

.c-article--featured .c-article__title {
  color: #262B23;
}

.c-article--small {
  background: #ffffff;
  margin-bottom: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

@media (min-width: 760px) {
  .c-article--small {
    width: calc(50% - 30px);
  }
}

@media (min-width: 1200px) {
  .c-article--small {
    width: calc(33.333% - 30px);
  }
}

.c-article--small .c-article__category {
  padding: 5px 10px;
  font-size: 12px;
  position: absolute;
  top: 1.5rem;
  bottom: auto;
  left: auto;
  right: 1.5rem;
  z-index: 10;
}

.c-article--small .c-article__infos {
  padding: 30px 20px;
}

.c-article--small .c-article__title {
  font-size: 16px;
  margin-top: 10px;
}

.c-article--small .c-article__date {
  font-size: 12px;
  margin-top: 0;
}

.carte {
  color: #B79875;
}

.carte .bigTitle {
  font-size: 4rem;
}

@media screen and (max-width: 992px) {
  .carte .bigTitle {
    font-size: 30px;
  }
}

.carte .simple-container {
  padding-bottom: 10rem;
}

@media screen and (max-width: 992px) {
  .carte .simple-container {
    padding-bottom: 100px;
  }
}

.carte_content {
  margin-bottom: 4.62963vh;
}

@media screen and (max-width: 992px) {
  .carte_content {
    margin-bottom: 30px;
  }
}

.carte_content .carte_group {
  border-bottom: 1px solid #B79875;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.carte_content .carte_group:first-child {
  border-top: 1px solid #B79875;
}

.carte_content .carte_group_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.carte_content .carte_group_btn .plus {
  display: block;
  width: 4vw;
  height: 4vw;
  position: relative;
  border: 1px solid #B79875;
  border-radius: 100vmax;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group_btn .plus {
    width: 55px;
    height: 55px;
  }
}

.carte_content .carte_group_btn .plus::after, .carte_content .carte_group_btn .plus::before {
  display: block;
  width: 38.5%;
  height: 4px;
  background-color: #ffffff;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.carte_content .carte_group_btn .plus::after {
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.carte_content .carte_group_btn.open .plus::after {
  opacity: 0;
}

.carte_content .carte_group_content {
  overflow: hidden;
  transition: all 0.3s ease;
}

.carte_content .carte_group_content.open {
  padding-top: 20px;
}

.carte_content .carte_group_title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  color: #B79875;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group_title {
    font-size: 25px;
  }
}

.carte_content .carte_group .carte_product {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .carte_content .carte_group .carte_product div:first-child {
    max-width: 70%;
  }
}

.carte_content .carte_group .carte_product:last-child {
  margin-bottom: 0;
}

.carte_content .carte_group .carte_product .name {
  font-size: 2.5rem;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product .name {
    font-size: 18px;
  }
}

.carte_content .carte_group .carte_product .name .option {
  font-size: 1.2rem;
  font-style: italic;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product .name .option {
    font-size: 12px;
  }
}

.carte_content .carte_group .carte_product .desc {
  font-size: 1.6rem;
  margin-top: 1rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_group .carte_product .desc {
    font-size: 16px;
  }
}

.carte_content .carte_group .carte_product .price {
  color: #B79875;
}

.carte_content .carte_menu .carte_selection {
  margin-top: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_menu .carte_selection {
    margin-top: 20px;
  }
}

.carte_content .carte_menu .carte_selection .title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #B79875;
  color: #B79875;
}

@media screen and (max-width: 992px) {
  .carte_content .carte_menu .carte_selection .title {
    font-size: 18px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}

.carte_content .colum-prices_name {
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .carte_content .colum-prices_name {
    margin-bottom: 0;
    margin-top: 15px;
  }
}

.carte_content .colum-prices_name, .carte_content .colum-prices_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .carte_content .colum-prices_name, .carte_content .colum-prices_content {
    justify-content: space-between;
  }
}

.carte_content .colum-prices_name span, .carte_content .colum-prices_content span {
  width: 11.11111vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .carte_content .colum-prices_name span, .carte_content .colum-prices_content span {
    width: 30%;
  }
}

.carte_content .categorieTitles {
  margin-bottom: 5rem;
}

@media screen and (max-width: 992px) {
  .carte_content .categorieTitles {
    margin-bottom: 40px;
  }
}

.carte_content .categorieTitles_title1 {
  margin-bottom: 2rem;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .carte_content .categorieTitles_title1 {
    margin-bottom: 20px;
  }
}

.carte_content .categorieTitles_title2 {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .categorieTitles_title2 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.carte_content .vMenu {
  padding-top: 3rem;
}

.carte_content .vMenu_title1 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  color: #B79875;
}

@media screen and (max-width: 992px) {
  .carte_content .vMenu_title1 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.carte_content .vMenu_title2 {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .carte_content .vMenu_title2 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.carte_content .vMenu_price {
  font-size: 2.4rem;
  font-weight: 700;
  color: #B79875;
}

@media screen and (max-width: 992px) {
  .carte_content .vMenu_price {
    font-size: 18px;
  }
}
