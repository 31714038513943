.u-banner-cookies {
    position: fixed;
    bottom: 30px;
    left: 30px;
    transform: translateY(170%);
    z-index: 100000;
    overflow: hidden;
    opacity: 0;
    transition: all .5s ease .25s;

    @media screen and (max-width:$screen-md) {
        bottom: 15px;
        left: 30px;
        width: 100%;
    }

    &_content {
        width: 48rem;
        background-color: $noir;
        padding: 3rem 4rem;
        border-radius: 3rem;
        border: 1px solid $marron;
        display: flex;
        flex-direction: column;

        @media screen and (max-width:$screen-md) {
            width: calc(100vw - 60px);
            padding: 15px;
            display: block;
        }
    }

    .bot {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bouton {
            min-width: 16rem;
            padding: 0 2rem;
            font-size: 1.4rem;
            height: 3.5rem;
            border-radius: 2.5rem;
        }

        @media screen and (max-width:$screen-md) {
            width: 100%;
            margin-left: 0;
            flex-direction: row;

            .bouton {
                width: calc(50% - 5px);
                min-width: 0;
                margin: 0;
                height: 40px;
                font-size: 14px;
                border-radius: 25px;
            }
        }
    }

    &_parametre {
        font-family: $font;
        font-weight: $regular;
        font-size: 12px;
        text-transform: uppercase;
        color: $blanc;
        text-align: left;
        margin-top: 2rem;
        text-decoration: underline;
        margin-bottom: 2rem;

        @media screen and (max-width:$screen-md) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    &.active {
        transform: translateY(0);
        opacity: 1;

        @media screen and (max-width:$screen-md) {
            transform: translateY(0)translateX(0);
        }
    }
}

#tarteaucitronAlertBig {
    display: none !important;
}

// ******************************************
// *** Styles de base tarteaucitron (ne pas modifier)
// ******************************************

/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
        padding: 16px !important;
    }

    html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
        display: none !important;
    }

    #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
        width: 100% !important;
        display: inline-block;
    }

    li.tarteaucitronLine .tarteaucitronName span {
        width: 100% !important;
        display: inline-block;
    }

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
        width: 10% !important;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 0px;
        padding: 10px 0;
    }

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
        content: '\0025BE';
        font-weight: 700;
        font-size: 14px;
    }

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
        content: '\0025B4';
    }
}

@media screen and (min-width: 768px) {

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
        content: '\0025BE';
        font-weight: 700;
        font-size: 14px;
        margin-left: 15px;
    }

    html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
        content: '\0025B4';
        margin-left: 15px;
    }
}

/****/



/***
* Reset CSS
*/
#tarteaucitronRoot div,
#tarteaucitronRoot span,
#tarteaucitronRoot applet,
#tarteaucitronRoot object,
#tarteaucitronRoot iframe,
#tarteaucitronRoot h1,
#tarteaucitronRoot h2,
#tarteaucitronRoot h3,
#tarteaucitronRoot h4,
#tarteaucitronRoot h5,
#tarteaucitronRoot h6,
#tarteaucitronRoot p,
#tarteaucitronRoot blockquote,
#tarteaucitronRoot pre,
#tarteaucitronRoot a,
#tarteaucitronRoot abbr,
#tarteaucitronRoot acronym,
#tarteaucitronRoot address,
#tarteaucitronRoot big,
#tarteaucitronRoot cite,
#tarteaucitronRoot code,
#tarteaucitronRoot del,
#tarteaucitronRoot dfn,
#tarteaucitronRoot em,
#tarteaucitronRoot img,
#tarteaucitronRoot ins,
#tarteaucitronRoot kbd,
#tarteaucitronRoot q,
#tarteaucitronRoot s,
#tarteaucitronRoot samp,
#tarteaucitronRoot small,
#tarteaucitronRoot strike,
#tarteaucitronRoot strong,
#tarteaucitronRoot sub,
#tarteaucitronRoot sup,
#tarteaucitronRoot tt,
#tarteaucitronRoot var,
#tarteaucitronRoot b,
#tarteaucitronRoot u,
#tarteaucitronRoot i,
#tarteaucitronRoot center,
#tarteaucitronRoot dl,
#tarteaucitronRoot dt,
#tarteaucitronRoot dd,
#tarteaucitronRoot ol,
#tarteaucitronRoot ul,
#tarteaucitronRoot li,
#tarteaucitronRoot fieldset,
#tarteaucitronRoot form,
#tarteaucitronRoot label,
#tarteaucitronRoot legend,
#tarteaucitronRoot table,
#tarteaucitronRoot caption,
#tarteaucitronRoot tbody,
#tarteaucitronRoot tfoot,
#tarteaucitronRoot thead,
#tarteaucitronRoot tr,
#tarteaucitronRoot th,
#tarteaucitronRoot td,
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot canvas,
#tarteaucitronRoot details,
#tarteaucitronRoot embed,
#tarteaucitronRoot figure,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot output,
#tarteaucitronRoot ruby,
#tarteaucitronRoot section,
#tarteaucitronRoot summary,
#tarteaucitronRoot time,
#tarteaucitronRoot mark,
#tarteaucitronRoot audio,
#tarteaucitronRoot video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    /*background: initial;*/
    text-align: initial;
    text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
    transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot details,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot figure,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot section {
    display: block;
}

#tarteaucitronRoot ol,
#tarteaucitronRoot ul {
    list-style: none;
}

#tarteaucitronRoot blockquote,
#tarteaucitronRoot q {
    quotes: none;
}

#tarteaucitronRoot blockquote:before,
#tarteaucitronRoot blockquote:after,
#tarteaucitronRoot q:before,
#tarteaucitronRoot q:after {
    content: '';
    content: none;
}

#tarteaucitronRoot table {
    border-collapse: collapse;
    border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible,
#tarteaucitronRoot button:focus-visible {
    outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
    margin-top: 0 !important;
}

div#tarteaucitronServices {
    margin-top: 26px !important;
    height: auto !important;
}

#tarteaucitronServices::-webkit-scrollbar {
    width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
    background-color: #ddd;
    outline: 0px solid slategrey;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width:479px) {
    #tarteaucitron .tarteaucitronLine .tarteaucitronName {
        width: 90% !important;
    }

    #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
        float: left !important;
        margin: 10px 15px 5px;
    }
}

@media screen and (max-width:767px) {

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer,
    #tarteaucitron {
        background: #fff;
        border: 0 !important;
        bottom: 0 !important;
        height: 100% !important;
        left: 0 !important;
        margin: 0 !important;
        max-height: 100% !important;
        max-width: 100% !important;
        top: 0 !important;
        width: 100% !important;
    }

    #tarteaucitron .tarteaucitronBorder {
        border: 0 !important;
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
        border: 0 !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
        text-align: left !important;
    }

    .tarteaucitronName .tarteaucitronH2 {
        max-width: 80%;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
        text-align: center !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
        margin-bottom: 5px;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    #tarteaucitron {
        border: 0 !important;
        left: 0 !important;
        margin: 0 5% !important;
        max-height: 80% !important;
        width: 90% !important;
    }
}

#tarteaucitron {
    border-radius: 0 !important;
}

#tarteaucitronRoot div#tarteaucitron {
    left: 0;
    right: 0;
    top: 50% !important;
    transform: translateY(-50%);
    margin: auto;

    @media screen and (max-width:$screen-xl) {
        overflow: scroll;
    }
}

#tarteaucitron .clear {
    clear: both;
}

.tarteaucitronListCookies {
    display: block;
}

#tarteaucitron a {
    color: $noir;
    font-size: 11px;
    font-weight: $regular;
    text-decoration: none;
}

#tarteaucitronRoot button {
    background: transparent;
    border: 0;
}

#tarteaucitronAlertBig strong,
#tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a,
#tarteaucitronAlertSmall a {
    color: #fff;
}

#tarteaucitron strong {
    font-size: 22px;
    font-weight: 500;
}

#tarteaucitron ul {
    padding: 0;
}

#tarteaucitron .tarteaucitronH1,
#tarteaucitron .tarteaucitronH2,
#tarteaucitron .tarteaucitronH3,
#tarteaucitron .tarteaucitronH4,
#tarteaucitron .tarteaucitronH5,
#tarteaucitron .tarteaucitronH6 {
    display: block;
}

// ******************************************
// *** PERSONNALISATION
// ******************************************

// Style des titres généraux & couleurs des textes

span.tarteaucitronH3 {
    @extend %font;
    font-weight: $bold !important;
    color: $blanc !important;
    font-size: 16px !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
    @extend %font;
    font-size: 16px !important;
}

#tarteaucitronRoot * {
    color: $noir;
    background-color: $blanc;
}

#tarteaucitronRoot .tarteaucitronH1 {
    font-size: 3rem;
    font-weight: $bold;
    text-align: center;
    color: $noir;
    margin: 0 25px;
    margin-bottom: 4rem;

    @media screen and (max-width:$screen-md) {
        font-size: 25px;
        margin-bottom: 40px;
    }
}

#tarteaucitronRoot .tarteaucitronH2 {
    display: inline-block;
    margin: 12px 0 0 10px;
    color: $noir;
}

// Background de l'overlay
#tarteaucitronRoot button#tarteaucitronBack {
    background: $noir;
}

// ******************************************
// *** MODALE DE GESTION DES PARAMÈTRES
// ******************************************

// *** Background overlay
#tarteaucitronBack {
    background: $noir;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2147483646;
}

// *** Conteneur 
#tarteaucitron {
    display: none;
    max-height: 80%;
    left: 50%;
    margin: 0 auto 0 -430px;
    padding: 0;
    position: fixed;
    top: 6%;
    width: 860px;
    z-index: 2147483647;
    border-radius: 3rem;
}

// Partie basse de la modale 
#tarteaucitron .tarteaucitronBorder {
    background: #fff;
    height: auto;
    overflow: auto;
}

// Bouton de fermeture de la modale
#tarteaucitron #tarteaucitronClosePanel {
    background: $noir;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    padding: 4px 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 70px;
    z-index: 10000;
}

#tarteaucitron br {
    display: none;
}

// Elements masqués
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    display: none;
    position: relative;
}

// Titre des catégories (Cookies obligatoire / Mesure d'audience etc.)
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
    color: $blanc;
    display: block;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 20px;
    text-align: left;
    width: 100%;
    background: $noir;
}

// Texte affiché au clic sur une catégorie (exemple "Mesure d'audience")
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
    color: $blanc;
    display: none;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
    max-width: 290px;
    padding: 20px;
    position: absolute;
    z-index: 2147483647;
    line-height: normal;
    background: $noir;
}

// Ligne de cookies à autoriser (fond gris clair)
#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
    background: $blanc;
    margin: 0;
    overflow: hidden;
    padding: 15px;
}

// Partie haute de la modale
#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
    background: $blanc;
    margin-bottom: 0;
    margin-top: 21px;
    position: relative;
}

// Sous titre : "Préférences pour tous les services"
#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
    margin-left: 15px;
    margin-top: 2px;
}

// Texte en vis à vis des boutons "Autoriser" et "Interdire"
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
    display: inline-block;
    float: left;
    margin-left: 0px;
    text-align: left;
    width: 50%;
    font-family: $font;
    font-weight: $bold;
    font-size: 18px;
    line-height: 1.4;
}

// Conteneur des boutons "Autoriser" et "Interdire"
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    display: inline-block;
    float: right;
    margin: 7px 15px 0;
    text-align: right;

    @media screen and (max-width:$screen-md) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        margin-top: 5px;
    }
}

// Boutons "Autoriser" ou "Interdire"
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny {
    background: transparent;
    border: 2px solid $noir;
    color: $noir;
    font-family: $font;
    font-weight: $bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    border-radius: 2.5rem;
    height: 4rem;
    width: 16rem;
    margin: 10px;
    cursor: pointer;

    &:hover {
        background-color: $noir;
        color: $blanc;
    }

    @media screen and (max-width:$screen-md) {
        height: 40px;
        width: 120px;
        border-radius: 25px;
        font-size: 12px;
    }
}

// Bouton "Autoriser" une fois cliqué
#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
    background: #1B870B;
    opacity: 1;

    &:hover {
        background: #1B870B;
        opacity: 1;
    }
}

// Bouton "interdire" une fois cliqué
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
    background: red;
    color: $blanc;
    opacity: 1;
}

// Bouton "Autoriser" se trouvant dans une ligne autorisée
#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
    background: #1B870B;
    color: $blanc;
    border: none;

    &:hover {
        background: #1B870B;
        color: $blanc;
        border: none;
    }
}

// Bouton "Interdire" se trouvant dans une ligne refusée
#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
    background: red;
    color: $blanc;
    border: none;

    &:hover {
        color: $blanc;
        background: red;
    }
}

// Titre des cookies (exemple : Google Analytics (gtag.js))
#tarteaucitron .tarteaucitronH3 {
    font-size: 18px;
    color: $noir !important;
}

// Texte d'introduction de la modale (border top et bottom)
div#tarteaucitronInfo {
    display: block !important;
    position: relative !important;
    text-align: center !important;
    max-width: 80% !important;
    padding: 15px 30px !important;
    margin: -10px auto 20px !important;
    font-size: 12px !important;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: $noir;
    line-height: 1.4 !important;
    background-color: $noir !important;
}

// Boutons non actifs
.tarteaucitronIsAllowed .tarteaucitronDeny {
    opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
    opacity: 0.4 !important;
}

.tarteaucitronLine .tarteaucitronAllow,
.tarteaucitronLine .tarteaucitronDeny {
    opacity: 1;
}

// Boutons actifs (ou déjà cliqués)
.tarteaucitronIsAllowed .tarteaucitronAllow {
    opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
    opacity: 1 !important;
}

// Bouton sélectionné par défaut (ne peut pas être interdit)
#tarteaucitronServices_mandatory button.tarteaucitronAllow {
    opacity: 1;
}

// Affichage / masquage de la modale
.tarteaucitron-display-block {
    display: block;
}

.tarteaucitron-display-none {
    display: none;
}