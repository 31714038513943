.p-footer {
    position: relative;
    z-index: 100;
    background-color: $marron-fonce;
    color: $blanc;
    padding-bottom: 40px;
    @extend .footer-text;

    p {
        @extend .footer-text;
    }

    @media screen and (max-width:$screen-md) {
        text-align: center;

        p,
        a {
            text-align: center;
        }
    }

    .simple-container {
        position: relative;
    }

    .line {
        background-color: $marron-clair;
        width: 100%;
        position: absolute;
        height: 1px;
        top: 172px;
        z-index: -1;

        @media screen and (max-width:$screen-md) {
            width: calc(100% - 60px);
        }
    }

    .logo {
        position: relative;
        margin-left: 50%;
        transform: translate(-50%, -37px);
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: '';
            background-color: $marron-fonce;
            border-radius: 100vmax;
            position: absolute;
            top: 0;
            width: 125px;
            height: 125px;
            z-index: -1;
        }

        &::after {
            content: '';
            background-color: $marron-fonce;
            position: absolute;
            bottom: 0;
            width: 230px;
            height: 125px;
            z-index: -1;

            @media screen and (max-width:$screen-md) {
                width: 174px;
            }
        }

        img {
            width: 139px;
            margin-top: 16px;
        }
    }

    &_grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-between;

        @media screen and (max-width:$screen-md) {
            grid-template-columns: 1fr;
            gap: 90px;
            margin-top: 20px;
        }
    }

    &_nav {
        display: flex;
        gap: vw(100px);

        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            align-items: center;
            gap: 34px;
        }

        div {
            gap: vw(30px);
            display: flex;
            flex-direction: column;
            @extend .footer-text;

            @media screen and (max-width:$screen-md) {
                gap: 34px;
            }
        }
    }

    &_social {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;

        @media screen and (max-width:$screen-md) {
            align-items: center;
            gap: 38px;
        }

        .social {
            font-size: 23px;
            @include fluid-text(16px, 23px, 1, 991px, 1920px);
            display: flex;
            gap: vw(30px);

            @media screen and (max-width:$screen-md) {
                font-size: 23px;
            }
        }
    }

    &_infos {
        @media screen and (max-width:$screen-md) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                position: absolute;
                content: '';
                background-color: $marron-clair;
                width: 100%;
                top: -40px;
                height: 1px;
            }

            &::after {
                position: absolute;
                content: '';
                background-color: $marron-clair;
                width: 100%;
                bottom: -40px;
                height: 1px;
            }
        }
    }

    .copyright {
        margin-top: vw(85px);

        p {
            font-size: 13px;
            text-align: center;
        }

        @media screen and (max-width:$screen-md) {
            margin-top: 40px;
        }
    }

    a:hover {
        color: $marron-clair;
    }
}

.periodeOuverture {
    width: 100%;
    background-color: $beige;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 7rem;
    padding-left: 30px;
    padding-right: 30px;

    @media screen and (max-width:$screen-md) {
        padding-top: 50px;
        padding-bottom: 60px;
    }

    .h2 {
        font-weight: bold;
        color: $marron-fonce;
        
        @media screen and (max-width:$screen-md) {
            text-align: center;
        }
    }

    &_content {
        margin-top: 30px;

        @media screen and (min-width:$screen-md) {
            margin-top: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            color: $marron;

            @media screen and (max-width:$screen-md) {
                text-align: center;
            }

            &+p {
                margin-left: 3vw;

                @media screen and (max-width:$screen-md) {
                    margin-top: 25px;
                }
            }
        }
    }
}