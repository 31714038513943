.activites,
.partenaires {
    &_content {
        background-color: $marron-fonce;
        padding-top: vw(150px);
        padding-bottom: vw(220px);

        @media screen and (max-width:$screen-md) {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        // ===================================
        // *** ARTICLES CARDS
        // ===================================

        .c-article {

            // -------------------- ** TEXT CONTENT **

            &__infos {
                background-color: $beige;

                .bouton {
                    color: $marron-fonce;
                }
            }

            // -------------------- ** TITLE **

            &__title {
                color: $marron-fonce;
            }

            // -------------------- ** DATE **

            &__date {
                color: $marron-fonce;
            }

            &__preview {
                color: $marron-clair;
            }

        }
    }
}