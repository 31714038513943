.c-formulaire {
    position: relative;
    overflow: hidden;

    &_row {
        @media screen and (min-width:$screen-md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 30px;
        }

        &:last-child {
            margin-top: vw(40px);
        }
    }

    &_field {
        position: relative;

        &.robot{
            position: absolute;
            left: -9999px;
            top: -9999px;
        }

        @media screen and (max-width:$screen-md) {
            margin-top: 20px;
        }

        &+& {
            margin-top: vw(20px);
        }

        &_label {
            display: block;
            margin-bottom: 1rem;
            @include fluid-text(14px, 16px, 1, 991px, 1920px);
            @include fluid-text(14px, 16px, 1, 320px, 425px);

            @media screen and (max-width:$screen-md) {
                margin-bottom: 10px;
            }
        }

        .form-radio {
            position: relative;
            padding-left: 40px;
            margin-bottom: 10px;

            input[type=radio],
            input[type=checkbox] {
                appearance: none;
                opacity: 0;
                width: 0;
                height: 0;
            }

            label {
                width: 23.44vw;
                display: inline-block;
                font-family: $font;
                @include fluid-text(11px, 13px, 1.4, 991px, 1920px);
                font-weight: normal;
                color: $blanc;
                cursor: pointer;
                margin: 0;

                @media screen and (max-width:$screen-md) {
                    font-size: 13px;
                    width: 100%;
                }

                &:after {
                    display: block;
                    content: '';
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    border: solid 1px $marron-clair;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 1;
                    transition: all .2s ease;
                }

                &:before {
                    display: block;
                    content: '';
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background: $marron-clair;
                    position: absolute;
                    left: 5px;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                    margin: auto;
                    transition: all .2s ease;
                    opacity: 0;
                }
            }

            input[type=radio]:checked+label,
            input[type=checkbox]:checked+label {
                &:before {
                    opacity: 1;
                }
            }
        }

        ::-webkit-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        ::-moz-placeholder {
            color: $blanc;
            opacity: 1;
        }

        ::-ms-placeholder {
            color: $blanc;
            opacity: 1;
        }

        ::-ms-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        input[type=tel],
        input[type=email],
        input[type=text],
        textarea {
            width: 100%;
            transition: all .5s ease;
            border: solid 1px $marron-clair;
            color: $blanc;
            font-size: 14px;
            padding: 0 15px;
            border-radius: 25px;
            height: 50px;
            @include fluid-text(13px, 16px, 1, 991px, 1920px);
            @include fluid-text(14px, 16px, 1, 320px, 425px);

            @media (min-width:$screen-md) {
                height: 5rem;
                border-radius: 2.5rem;
                padding: 0 2rem;
            }
        }

        textarea {
            padding-top: 10px;
            height: 180px;

            @media(min-width:$screen-md) {
                height: calc(21rem + 30px);
                padding-top: 15px;
            }
        }

        .dropdown-toggle {
            display: block;
            width: 100%;
            position: relative;

            .dropdown-toggle-btn {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                justify-content: space-between;
                width: 100%;
                vertical-align: middle;
                transition: all .5s ease 0s;
                border: solid 1px $blanc;
                color: $blanc;
                font-size: 14px;
                height: 8rem;
                max-height: 60px;
                padding: 0 42px 0 15px;
                position: relative;

                @media (min-width:$screen-md) {
                    font-size: 16px;
                }

                &:focus {
                    color: $blanc;
                    background: $blanc;
                    border-color: $blanc;
                }

                .icon-cursor {
                    @include icon(2rem);
                }
            }

            ul.dropdown {
                @include position(absolute, 2, 100%, 0, auto, 0);
                background: $blanc;
                overflow: hidden;
                max-height: 0;
                transition: all .5s ease;
                font-size: 14px;

                @media (min-width:$screen-md) {
                    font-size: 16px;
                }

                li {
                    max-height: 0;
                    padding: 0 15px;
                    line-height: 50px;
                    transition: all .5s ease;
                    color: $blanc;

                    a:hover {
                        opacity: .5;
                    }
                }

            }

            &.open {
                a.toggle {
                    transition-delay: 0s;
                }

                ul.dropdown {
                    max-height: 300px;
                    overflow: auto;
                    transition: all .5s ease 0s;

                    li {
                        max-height: 50px;
                        transition-delay: 0s;
                    }
                }
            }
        }

        &::after {
            margin-top: 0;
            display: block;
            content: "";
            height: 0;
            opacity: 0;
            transition: 0.5s all ease-out;
        }

        &.error::after {
            height: 15px;
            margin: 5px;
            content: attr(data-message);
            color: red;
            opacity: 1;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: 0.05em;
            top: 0;
            text-align: left;
        }
    }

    &_send {
        margin-top: 2rem;

        .bouton {
            min-width: vw(150px);
        }

        .h-captcha{
            margin : 2rem 0;
        }

        @media screen and (max-width:$screen-md) {
            margin-top: 40px;
            justify-content: center;
        }
    }

    // FORM FILE UPLOAD
    &_file {
        input[type=file] {
            opacity: 0;
            width: 0;
            height: 0;
            appearance: none;
            position: absolute;
        }

        label {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: $blanc;
            color: $blanc;
            cursor: pointer;
            height: 40px;
            padding: 0 20px;
            font-size: 14px;

            @media (min-width:$screen-sm) {
                font-size: 15px;
                padding: 0 25px;
                height: 50px;
            }

            @media (min-width:$screen-md) {
                font-size: 16px;
                padding: 0 30px;
                height: 60px;
            }

            &:after,
            &:before {
                content: normal;
                display: none;
            }
        }

        .name-file {
            display: block;
            max-width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    /*************************************************
    // FORMULAIRE / VALIDATION
    *************************************************/

    &_overlay {
        @include position(fixed, 1000, 0, 0, 0, 0);
        background-color: rgba($color: $noir, $alpha: .8);
        width: auto;
        transition: all .6s ease;
        cursor: pointer;

        @media (max-width:$screen-md) and (min-width: $screen-sm) {
            width: calc(100% - 30px);
            margin: auto;
        }

        &_text {
            @include position(absolute, 100, 40%, 0, auto, 0);
            margin: auto;
            width: 100%;
            height: auto;
            min-height: auto;
            display: block;
            opacity: 0;
            transform: translateY(-50%);
            transition: all .5s ease;

            @media (min-width:$screen-sm) {
                width: 75%;
            }

            @media (min-width:$screen-md) {
                width: 60%;
            }

            p {
                line-height: 1.33;
                text-align: center;
                color: $marron-fonce;
                font-weight: 600;
                font-size: 16px;
                padding: 5vw 30px;
                background: rgba(255, 255, 255, 1);

                @media (min-width:$screen-md) {
                    font-size: 18px;
                }

                @media (min-width:$screen-md) {
                    font-size: 20px;
                }

                @media (min-width:$screen-xl) {
                    font-size: 24px;
                }

                .icon {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    width: 25px;
                    height: auto;
                }
            }

            &.active {
                opacity: 1;
                top: 50%;
            }
        }

        &.close {
            opacity: 0;

            .form-msg {
                opacity: 0;
                top: 60%;
            }
        }

        &.under {
            z-index: -1;
        }
    }
}

#recaptcha {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-bottom: 1vw;

    @media screen and (max-width:$screen-md) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}