.chambres {

    &::after {
        z-index: -1;
        content: "";
        background-color: $beige;
        height: 100%;
        width: calc(42.44vw + 30px);
        position: absolute;
        right: 0;
        top: 0;

        @media screen and (max-width: $screen-md) {
            display: none;
        }
    }

    &-section1 {
        position: relative;
        padding-top: calc(51.302vw / 2 + 12.4vw);
        padding-bottom: vw(270px);

        @media screen and (max-width: $screen-md) {
            padding-top: calc(60vw / 2 + 70px);
            padding-bottom: 100px;
            background-color: $beige;
        }

        &::after {
            @media screen and (max-width: $screen-md) {
                content: "";
                background-color: $beige;
                position: absolute;
                right: 0;
                top: 0;
                background-color: $blanc;
                width: 100%;
                height: 645px;
            }
        }

        .bloc1 {
            .grid-container {
                @media screen and (min-width: $screen-md) {
                    &:not(:first-child) {
                        align-items: center;
                    }

                    &:nth-child(2) {
                        .image-figure {
                            margin: -5.21vw 0;
                        }
                    }
                }
            }
        }
    }

    &-section2 {
        background-color: $marron-fonce;
        padding: vw(200px) 0;

        @media screen and (max-width: $screen-md) {
            padding: 75px 0 110px;

            .h3 {
                @include fluid-text(25px, 35px, 1.43, 320px, 390px);
            }
        }

        &_grid {
            margin-top: vw(80px);
            column-gap: vw(150px);
            row-gap: vw(100px);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $screen-md) {
                grid-template-columns: 1fr 1fr;
                row-gap: 80px;
                column-gap: 30px;
                justify-content: space-between;
                margin-top: 80px;
            }

            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: vw(40px);

                @media screen and (max-width: $screen-md) {
                    gap: 40px;
                }

                p {
                    text-align: center;
                    white-space: nowrap;
                }
            }
        }
    }

    &-section3 {
        position: relative;
        padding-top: vw(254px);

        @media screen and (max-width: $screen-md) {
            padding-top: 70px;
            background-color: $beige;
        }

        &::after {
            content: "";
            background-color: $beige;
            height: 100%;
            width: calc(50vw);
            position: absolute;
            right: 0;
            top: 0;

            @media screen and (max-width: $screen-md) {
                background-color: $blanc;
                width: 100%;
                height: 645px;
            }
        }
    }

    &-informations {
        background-color: $marron-fonce;
        padding-top: vw(185px);
        padding-bottom: vw(160px);

        @media screen and (max-width:$screen-md) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .h1 {
            margin-bottom: vw(35px);

            @media screen and (max-width:$screen-md) {
                margin-bottom: 35px;
            }
        }

        p {
            @extend .footer-text;

            @media screen and (max-width:$screen-md) {
                margin-top: 40px;
            }

            strong,
            span {
                color: $marron-clair;
            }

            strong{
                @extend .paragr;
            }

            br {
                display: block !important;
            }

            &+p {
                margin-top: vw(40px);
            }
        }
    }
}