.legals {
    background-color: $marron-fonce;

    #section-top {
        padding-top: 20rem;
        padding-bottom: 10rem;
        
        @media screen and (max-width:$screen-md) {
            padding-top: 150px;
            padding-bottom: 100px;
        }
    }
    
    &_titre {
        margin-bottom: 6rem;
    }

    &_texte {
        padding: 10rem 0;

        p {
            &+p {
                margin-top: 30px;
            }
        }
    }

    &_subtitle {
        display: block;
        color: $blanc;
        font-size: 18px;
        margin-top: 80px;
        margin-bottom: 30px;

        @media (min-width:$screen-md) {
            font-size: 24px;
        }
    }
}