.c-form-sort {
    width: 100%;
    position: fixed;
    top: 8rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    left: 0;
    background-color: $beige;
    z-index: 102;
    transition: all .3s ease;

    @media screen and (max-width:$screen-md) {
        top: 85px;
    }

    .simple-container {
        @media screen and (max-width:$screen-md) {
            padding: 0;
        }
    }

    &_selectors {
        overflow-x: auto;
        overflow-y: visible;
        width: auto;
        height: auto;

        @media screen and (max-width:$screen-md) {
            justify-content: space-between;
            width: 100%;
        }
    }

    &_select {
        width: 100%;
        position: relative;
        margin-top: 5rem;

        @media (min-width:$screen-sm) {
            width: auto;
            margin-top: 0;
        }

        &_title {
            position: relative;
            text-align: right;
            margin-bottom: 10px;
            font-size: 12px;

            @media (min-width:$screen-sm) {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                text-align: left;
                z-index: 1;
                transform: translateY(-120%);
            }
        }
    }

    // *** Input search part
    &__search {
        width: 100%;
        border: solid 1px $noir;
        color: $noir;
        height: 35px;
        margin-bottom: 15px;
        padding: 0 15px;
        font-size: 14px;

        @media (min-width:$screen-sm) {
            width: 250px;
        }

        &::-webkit-input-placeholder {
            color: $noir;
            opacity: 1;
        }

        &::-moz-placeholder {
            color: $noir;
            opacity: 1;
        }

        &::-ms-placeholder {
            color: $noir;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            color: $noir;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $noir;
            opacity: 1;
        }
    }

    // *** Button part
    &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $font;
        font-weight: $medium;
        padding: 0 2rem;
        height: 6rem;
        border-radius: 3rem;
        color: $noir;
        border: 1px solid $marron;
        background-color: transparent;
        font-size: 1.8rem;
        margin-right: 2rem;

        @media screen and (max-width:$screen-md) {
            font-size: 18px;
            height: 40px;
            width: 50%;
            padding: 0 10px;
            border: none;
        }

        @media screen and (max-width:$screen-xss) {
            font-size: 14px;
        }

        &.button-home {
            display: none;
        }

        &:nth-child(2) {
            @media screen and (max-width:$screen-md) {
                width: 30%;
            }
        }

        &:last-child:not(.c-form-sort__button--small) {

            @media screen and (max-width:$screen-md) {
                width: 20%;
            }
        }

        .text {
            display: inline-block;

            @media screen and (max-width:$screen-md) {
                margin-right: 10px;
                max-width: 100px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden
            }
        }

        .reset {
            @media screen and (max-width:$screen-md) {
                display: none;
            }
        }

        .icons {
            margin-left: 10px;

            &.invers {
                margin-left: 0;
                margin-right: 10px;

                @media screen and (max-width:$screen-md) {
                    margin-right: 0;
                }
            }
        }

        .icon-triangle {
            @include icon(1.6rem);
            margin-left: 10px;
            color: $noir;
            transform: rotate(0);
            transition: all .3s ease-out;

            @media screen and (max-width:$screen-lg) {
                @include icon(14px);
            }
        }

        .icon-mag-reinit {
            @include icon(1.7rem);
            transform: rotate(-45deg)scaleX(-1);
            transition: all .3s ease-out;

            @media screen and (max-width:$screen-md) {
                @include icon(14px);
            }
        }

        // Bug flex with safari
        &:after,
        &:before {
            content: normal;
            display: none;
        }

        // *** Small version
        &--small {
            position: relative;
            min-width: 0;
            font-family: $font;
            font-weight: $regular;
            text-transform: none;
            font-size: 1.8rem;
            border: 1px solid $marron-clair;
            background: $marron-clair;
            color: $noir;
            height: 3.5rem;
            border-radius: 3.5rem;
            padding: 0 1.8rem;
            margin: 1rem;

            @media screen and (max-width:$screen-md) {
                font-size: 18px;
                height: 30px;
                margin: 0;
                margin-right: 10px;
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 0 15px;
                width: auto !important;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background: transparent !important;
                color: $noir !important;
            }

            &.active {
                background: transparent !important;
                color: $noir !important;
            }
        }

        &:hover,
        &.active {
            @media screen and (min-width:$screen-md) {
            background: $marron;
            color: $blanc;

            .icon-triangle {
                color: $blanc;
            }

            }
        }

        &.active {
            @media screen and (min-width:$screen-md) {
                background: $marron;

                .icon-triangle {
                    color: $blanc;
                    transform: rotate(-180deg);
                }
            }
        }
    }


    // *** Input search part
    &_list {
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 50vh;
        opacity: 0;
        transition: all .3s ease-out;
        background: $beige;
        padding: 1.5rem 0;
        @include position(absolute, 1, 100%, 0, auto, 0);

        @media screen and (max-width:$screen-md) {
            padding: 15px;
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }

        // *** Items container

        &_items {
            width: auto;
        }

        // *** No results message

        &_notags {
            font-size: 12px;
            margin: 10px 0;
            color: $blanc;
        }
    }

}