.contact {

    &_header {
        padding-top: vw(250px);
        padding-bottom: vw(190px);
        background-color: $marron-fonce;

        @media screen and (max-width:$screen-md) {
            padding-top: 150px;
            padding-bottom: 100px;
        }

        &_title {
            opacity: 0;
            transform: translateY(-100%);
            animation: trans-opacity-top-100 1s ease .3s forwards;
            color: $marron-clair;
            margin-bottom: vw(45px);

            @media screen and (max-width:$screen-md) {
                margin-bottom: 45px;
            }
        }


        &_text {
            opacity: 0;
            transform: translateY(-100%);
            animation: trans-opacity-top-100 1s ease .5s forwards;
            color: $blanc;
            max-width: 30.5rem;
            @include fluid-text(11px, 20px, 1.43, 991px, 1920px);
            @include fluid-text(16px, 20px, 1.43, 320px, 390px);  

            @media screen and (max-width:$screen-md) {
                max-width: 100%;
            }

            &+p {
                margin-top: vw(35px);

                @media screen and (max-width:$screen-md) {
                    margin-top: 40px;
                }
            }

            a {
                color: $marron-clair;
            }
        }

        .c-formulaire {
            opacity: 0;
            animation: opacity 1s ease .8s forwards;
            @media screen and (max-width:$screen-md) {
                margin-top: 80px;
            }
        }
    }

    &_map {
        background-color: $beige;

        @media screen and (min-width:$screen-md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        &_left {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: vw(210px);
            padding-bottom: vw(230px);

            @media screen and (max-width:$screen-md) {
                padding-top: 75px;
                padding-bottom: 75px;
            }

            .logo {
                width: vw(290px);

                @media screen and (max-width:$screen-md) {
                    width: 215px;
                    max-width: 80%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            p {
                color: $marron;
                margin-top: vw(30px);

                @media screen and (max-width:$screen-md) {
                    margin-top: 40px;
                }
            }
        }

        &_right {
            width: 100%;

            @media screen and (max-width:$screen-md) {
                height: 595px;
            }
        }
    }
}