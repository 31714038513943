.error404 {

    background-color: $marron-fonce;
    .p-header,
    .p-nav,
    .p-footer {
        display: none;
    }

    &_content {
        height: 100vh;

        &_container {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .flex {
            flex-direction: column;
            align-items: start;
            justify-content: center;
            gap: vw(75px);
            @media screen and (max-width:$screen-md) {
                align-items: center;
                gap: 3.5vh;
            }
        }

        &_h1 {
            @include fluid-text(100px, 192px, 1.1, 991px, 1920px);
            @media screen and (max-width:$screen-md) {
                text-align: center;
            }
        }

        &_h2 {
            @include fluid-text(30px, 40px, 1.1, 991px, 1920px);
            @media screen and (max-width:$screen-md) {
                text-align: center;
            }
        }

        img {
            width: 100%;
            @media screen and (max-width:$screen-md) {
                width: 200px;
                margin-left: 50%;
                transform: translateX(-50%);
                margin-bottom: 6vh;
            }
        }
    }
}