.mag,
.article {
    background-color: $beige;

    .p-nav {
        color: $marron-fonce;
        background-color: $beige;

        .bouton {
            color: $marron-fonce;
        }

        .logo {
            opacity: 1;
            width: vw(110px);

            @media screen and (max-width:$screen-md) {
                width: 110px;
            }
        }
    }

    #section-top {
        overflow: hidden;
        position: relative;
    }

    &_content {
        background-color: $beige;
        position: relative;
        padding-top: 15.625vw;
        padding-bottom: 10rem;

        @media screen and (max-width:$screen-md) {
            padding-top: 180px;
            padding-bottom: 80px;
        }

        &_title {
            margin-bottom: 5rem;
            font-size: 4.5rem;
            @extend %font-bold;
            font-family: $font;
            color: $noir;

            &_sub {
                display: block;
                margin-top: 10px;
                font-size: 50%;
                color: $gris;
                @extend %font-bold;
            }
        }
    }
}