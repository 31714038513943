.c-bookBar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: $noir;
    min-width: vw(1010px);
    padding: 0 3.5rem;
    height: 10.5rem;
    border-radius: 5rem;
    z-index: 10;

    @media screen and (max-width:$screen-md) {
        height: auto;
        flex-direction: column;
        min-width: 0;
        width: 100%;
        border-radius: 30px;
        padding: 35px 6.410256410256411vw;
    }

    &_content {
        display: flex;
        align-items: center;

        @media screen and (max-width:$screen-md) {
            flex-direction: column;
            width: 100%;
        }
    }

    &_field {
        position: relative;
        margin-right: vw(20px);

        @media screen and (max-width:$screen-md) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }

        &:last-child {
            margin-right: vw(135px);

            @media screen and (max-width:$screen-md) {
                margin-right: 0;
            }
        }

        ::-webkit-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        ::-moz-placeholder {
            color: $blanc;
            opacity: 1;
        }

        ::-ms-placeholder {
            color: $blanc;
            opacity: 1;
        }

        ::-ms-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: $blanc;
            opacity: 1;
        }

        input[type=tel],
        input[type=email],
        input[type=text] {
            position: relative;
            font-family: $font;
            border: 1px solid $marron;
            border-radius: 2.5rem;
            height: 5rem;
            width: 20rem;
            cursor: pointer;
            color: $blanc;
            padding: 0 vw(20px);
            z-index: 1;
            @include fluid-text(12px, 18px, 1, 991px, 1920px);
            @include fluid-text(14px, 17px, 1, 320px, 425px);

            @media screen and (max-width:$screen-md) {
                width: 100%;
                height: 50px;
                border-radius: 25px;
                padding: 0 20px;
            }
        }

        .icon-cursor {
            @include icon(1.5rem);
            position: absolute;
            top: 50%;
            right: vw(20px);
            transform: translateY(-50%);
            z-index: 0;
            color: $marron;

            @media screen and (max-width:$screen-md) {
                right: 20px;
                @include icon(15px);
            }
        }

        .dropdown-toggle {
            display: block;
            width: 100%;
            position: relative;

            .dropdown-toggle-btn {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                justify-content: space-between;
                height: 5rem;
                width: 27rem;
                font-family: $font;
                @include fluid-text(12px, 18px, 1, 991px, 1920px);
                @include fluid-text(14px, 17px, 1, 320px, 425px);
                border: 1px solid $marron;
                border-radius: 2.5rem;
                cursor: pointer;
                color: $blanc;
                padding: 0 vw(20px);
                z-index: 1;
                transition: all .5s ease 0s;

                @media screen and (max-width:$screen-md) {
                    width: 100%;
                    height: 50px;
                    border-radius: 25px;
                    padding: 0 20px;
                }

                .icon-cursor {
                    @include icon(1.5rem);
                    color: $marron;

                    @media screen and (max-width:$screen-md) {
                        right: 20px;
                        @include icon(15px);
                    }
                }
            }

            ul.dropdown {
                @include position(absolute, 2, 100%, 0, auto, 0);
                background: $noir;
                overflow: hidden;
                max-height: 0;
                border-radius: 0 0 2.5rem 2.5rem;
                transition: all .5s ease;

                @media screen and (max-width:$screen-md) {
                    border-radius: 0 0 25px 25px;
                }

                li {
                    max-height: 0;
                    opacity: 0;
                    transform: translateY(-50%);
                    transition: all .5s ease;

                    button {
                        @include fluid-text(12px, 19px, 1, 991px, 1920px);
                        @include fluid-text(14px, 17px, 1, 320px, 425px);
                        font-family: $font;
                        padding: 10px 20px;
                        text-align: left;
                        width: 100%;
                        color: $blanc;

                        &:hover {
                            background-color: $marron;
                        }
                    }
                }

            }

            &.open {

                .dropdown-toggle-btn {
                    border-radius: 2.5rem 2.5rem 0 0;
                    transition: all .5s ease 0s;

                    @media screen and (max-width:$screen-md) {
                        border-radius: 25px 25px 0 0;
                    }
                }

                ul.dropdown {
                    max-height: 500vh;
                    overflow: auto;
                    border-left: 1px solid $marron;
                    border-right: 1px solid $marron;
                    border-bottom: 1px solid $marron;
                    padding: 1rem 0;
                    transition: all .5s ease 0s;

                    @media screen and (max-width:$screen-md) {
                        padding: 20px 0;
                    }

                    li {
                        opacity: 1;
                        transform: translateY(0);
                        max-height: 400vh;
                        transition-delay: 0s;
                        
                    }
                }
            }
        }

        &::after {
            display: block;
            content: 'Merci de renseigner ce champ';
            position: absolute;
            min-width: 21rem;
            bottom: calc(100% + 5px);
            left: 0;
            background-color: $blanc;
            padding: 1rem 2rem;
            border-radius: 2rem;
            font-family: $font;
            @include fluid-text(15px, 14px, 1.4, 991px, 1920px);
            @include fluid-text(12px, 14px, 1, 320px, 425px);
            color: red;
            font-weight: $bold;
            text-align: left;
            z-index: -1;
            opacity: 0;
            pointer-events: none;

            @media screen and (max-width:$screen-md) {
                padding: 10px 15px;
                border-radius: 20px;
                min-width: 0;
                text-align: center;
                width: auto;
            }
        }

        &.error {
            &::after {
                z-index: 10;
                opacity: 1;
                pointer-events: initial;
            }
        }
    }

    &_send {

        @media screen and (max-width:$screen-md) {
            width: 100%;
        }

        .bouton-send {
            display: flex;
            align-items: center;
            justify-content: center;
            width: vw(145px);
            height: 5rem;
            border-radius: 2.5rem;
            color: $blanc;
            font-family: $font;
            @include fluid-text(14px, 18px, 1, 991px, 1920px);
            @include fluid-text(14px, 17px, 1, 320px, 425px);
            background-color: $marron;
            transition: all .3s ease;

            @media screen and (max-width:$screen-md) {
                width: 100%;
                height: 50px;
                border-radius: 25px;
            }

            &:hover {
                background-color: $blanc;
                color: $marron-clair;
                transition: all .3s ease;
            }
        }
    }
}