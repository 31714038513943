/*************************************************
// BOUTONS
*************************************************/

.bouton,
.bouton:focus {
    display: inline-flex;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;
    position: relative;
    padding: 5px 15px;
    padding-top: 8px;
    background-color: transparent;
    border: 1px solid $marron-clair;
    border-radius: 100vmax;
    color: $blanc;
    font-family: $font;
    transition: all .3s ease;
    @include fluid-text(14px, 18px, 1.43, 991px, 1920px);

    @media screen and (max-width:$screen-md) {
        height: 50px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 18px;
    }

    &.float-right {
        float: right;

        @media screen and (max-width:$screen-md) {
            float: none;
        }
    }

    &:hover {

        @media screen and (min-width:$screen-md) {
            background-color: $marron-clair;
            color: $blanc;
            transition: all .3s ease;
        }
    }



    &.v-marron-clair {
        border: 1px solid $marron-clair;
        background-color: $marron-clair;
        color: $blanc;

        &:hover {

            @media screen and (min-width:$screen-md) {
                background-color: transparent;
                color: $blanc;
                transition: all .3s ease;
            }
        }
    }
}