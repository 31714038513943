// ===================================
// *** CONTAINERS ARTICLES
// ===================================

.c-articles {

    &_container {
        margin: 0 -15px;
        width: auto;
    }

    // *** NO ARTICLES

    &--no-articles {
        text-align: center;
        @extend %font-bold;
        font-size: 14px;
        margin-top: 5rem;

        @media (min-width:$screen-sm) {
            font-size: 16px;
        }

        @media (min-width:$screen-lg) {
            font-size: 18px;
        }
    }

    &_see-more {
        width: 100%;
        text-align: center;
        margin-top: 4rem;

        @media screen and (max-width:$screen-md) {
            margin-top: 0;
        }

        .bouton {
            color: $marron-fonce;
        }

        &.hidden {
            display: none;
        }
    }
}

// ===================================
// *** ARTICLES CARDS
// ===================================

.c-article {

    // -------------------- ** IMAGES **

    &__img {
        width: 100%;
        height: 15.625vw;
        overflow: hidden;

        @media screen and (max-width:$screen-md) {
            height: 58.666666666666664vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transform: scale(1);
            transition: all .3s ease;
        }
    }

    // -------------------- ** TEXT CONTENT **

    &__infos {
        background-color: $marron-fonce;
        position: relative;
        height: calc(100% - 15.625vw);
        padding: 2.6041666666666665vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        @media screen and (max-width:$screen-md) {
            height: auto;
            padding: 30px 25px;
            padding-bottom: 45px;
        }

        div:last-child {
            @media screen and (min-width:$screen-md) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .arrow {
            position: absolute;
            bottom: 1.5625vw;
            right: 1.5625vw;
            z-index: 3;

            @media screen and (max-width:$screen-md) {
                bottom: 20px;
                right: 20px;
            }

            .icon-arrow {
                @include icon(1.5625vw);
                color: $noir;

                @media screen and (max-width:$screen-md) {
                    @include icon(25px);
                }
            }
        }
    }

    // -------------------- ** CATEGORY BUTTON **

    &__category {
        display: block;
        font-family: $font;
        font-weight: $regular;
        font-size: 20px;
        color: $blanc;
        line-height: 1;
        margin-top: 15px;
        margin-bottom: 25px;

        @media (min-width:$screen-md) {
            font-size: 2rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }

    // -------------------- ** TITLE **

    &__title {
        @extend %font;
        @include fluid-text(22px, 32px, 1.2, 991px, 1920px);
        color: $marron-clair;
        margin: 0;
        transition: all .25s ease-out;

        @media screen and (max-width:$screen-md) {
            font-size: 32px;
        }

        @media screen and (max-width:$screen-xss) {
            font-size: 22px;
        }
    }

    // -------------------- ** DATE **

    &__date {
        display: inline-block;
        font-size: 1.6rem;
        font-family: $font;
        font-weight: $regular;
        color: $marron-clair;
        transition: all .25s ease-out;

        @media screen and (max-width:$screen-md) {
            font-size: 20px;
            margin-bottom: 25px;
        }
    }

    &__preview {
        display: inline-block;
        font-size: 2rem;
        font-family: $font;
        font-weight: $regular;
        color: $blanc;
        margin-top: vw(25px);
        margin-bottom: vw(25px);
        transition: all .25s ease-out;

        @media screen and (max-width:$screen-md) {
            font-size: 20px;
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }

    // ===================================
    // *** ARTICLES CARDS
    // ===================================

    &--card {
        position: relative;
        margin-bottom: 50px;
        margin-left: 15px;
        margin-right: 15px;
        min-height: 26.5625vw;
        width: calc(100% - 30px);

        @media (min-width:$screen-md) {
            width: calc(33.333% - 30px);
        }

        transition: all .25s ease-out .25s,
        width .25s ease-out 0s,
        padding .25s ease-out 0s,
        max-height .25s ease-out 0s;
        opacity: 1;
        transform: scale(1);
        max-height: 100vh;
        overflow:hidden;
        z-index: 7;

        &.inactive {
            opacity: 0;
            transform: scale(.5);
            width: 0;
            max-height: 0;
            margin: 0;
            padding: 0;
            visibility: hidden;
            transition:
                all .3s ease-out 0s,
                width .1s linear .25s,
                padding .1s linear .25s,
                max-height .1s linear .25s;
        }

        a {
            display: block;
            height: 100%;
        }

        &:hover {
            .c-article__infos {

                .arrow {
                    animation: arrow-mag 1s ease infinite;
                }
            }
        }
    }

    // ===================================
    // *** ARTICLES EN UNE (FEATURED)
    // ===================================

    &--featured {
        width: 100%;
        background-color: $noir;

        @media (min-width:$screen-md) {
            width: calc((33.333% - 15px) * 2);
        }

        a {
            @media screen and (min-width:$screen-md) {
                display: flex;
            }
        }

        .c-article__img {

            @media (min-width:$screen-sm) {
                height: 100%;
                width: 35.9375vw;
            }
        }

        .c-article__infos {
            background-color: $noir;

            @media (min-width:$screen-sm) {
                width: calc(100% - 35.9375vw);
                height: 100%;
            }

            .arrow {
                .icon-arrow {
                    color: $noir;
                }
            }
        }

        .c-article__title {
            color: $noir;
        }
    }

    // ===================================
    // *** ARTICLES SMALL (SIMILAR ARTICLES / PAGE ARTICLE)
    // ===================================

    &--small {
        background: $blanc;
        margin-bottom: 30px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);

        @media (min-width:$screen-sm) {
            width: calc(50% - 30px);
        }

        @media (min-width:$screen-lg) {
            width: calc(33.333% - 30px);
        }

        .c-article__category {
            padding: 5px 10px;
            font-size: 12px;
            @include position(absolute, 10, 1.5rem, 1.5rem);
        }

        .c-article__infos {
            padding: 30px 20px;
        }

        .c-article__title {
            font-size: 16px;
            margin-top: 10px;
        }

        .c-article__date {
            font-size: 12px;
            margin-top: 0;
        }
    }

}