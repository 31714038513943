.restaurant {
    &::after {
        z-index: -1;
        content: "";
        background-color: $beige;
        height: 100%;
        width: calc(42.44vw + 30px);
        position: absolute;
        right: 0;
        top: 0;

        @media screen and (max-width: $screen-md) {
            display: none;
        }
    }

    &-section1 {
        position: relative;
        padding-top: calc(51.302vw / 2 + 12.4vw);
        margin-bottom: -11.46vw;

        @media screen and (max-width: $screen-md) {
            padding-top: calc(60vw / 2 + 70px);
            margin-bottom: -47px;
            background-color: $beige;
        }

        &::after {
            @media screen and (max-width: $screen-md) {
                content: "";
                background-color: $beige;
                position: absolute;
                right: 0;
                top: 0;
                background-color: $blanc;
                width: 100%;
                height: 645px;
            }
        }

        .bloc1 {
            &_carre {
                margin-top: vw(50px);
                width: 49rem;
                padding: vw(35px) vw(50px);
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width:$screen-md) {
                    margin-top: 50px;
                    padding: 35px 15px;
                    width: 100%;
                }

                p {
                    padding: 0 !important;
                    margin: 0 !important;
                    text-align: center;
                }
            }
        }
    }

    &-carte {
        background-color: $marron-fonce;
        padding: vw(400px) 0 vw(300px);

        @media screen and (max-width:$screen-md) {
            padding: 180px 0 100px;
        }

        .h1+p {
            margin: 15px 0 vw(100px);

            @media screen and (max-width:$screen-md) {
                margin-bottom: 75px;
            }
        }
    }
}