.p-menu {
    position: fixed;
    background-color: $marron-fonce;
    top: -140%;
    bottom:140%;
    left: 0;
    z-index: 103;
    width: 100%;
    transition: all 0.4s ease 0.3s;

    &_content {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        @extend .paragr;
        color: $blanc;

        @media screen and (max-width: $screen-md) {
            height: 100%;
            padding-top:110px;
        }
    }
    
    .logo {
        img {
            max-height: 25vh;
            margin-bottom: 5vh;
        }
    }

    .line {
        height: 1px;
        width: 57px;
        background-color: $marron-clair;
    }

    &_nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3.5vh;
    }

    &_bottom {
        position: absolute;
        bottom: 0;
        width: 100vw;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 50px;
        .item {
            border: 1px solid $marron-clair;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    //* Animation d'apparition des links dans le menu
    .anim-link {
        transform: translateY(100%);
        opacity: 0;
        transition: all 0.3s ease;
    }

    //* Animation à l'ouverture du menu
    &.open {
        top: 0;
        bottom:0;
        transition: all 0.4s ease 0.2s;

        .anim-link {
            transform: translateY(0);
            opacity: 1;
            transition: all 0.3s ease;

            @for $i from 1 through 30 {
                &:nth-child(#{$i}) {
                    transition-delay: 0.3s + $i * 0.1s;
                }
            }
        }
    }
}
