.home {
    .p-header {
        padding-top: vw(100px);
        @media screen and (max-width:$screen-md) {
            padding-top: 100px;
        }
    }
    &::after {
        z-index: -1;
        content: "";
        background-color: $beige;
        height: 100%;
        width: calc(42.44vw + 30px);
        position: absolute;
        right: 0;
        top: 0;
        @media screen and (max-width: $screen-md) {
            display: none;
        }
    }
    .p-nav.masked {
        .logo {
            opacity: 0;
            pointer-events: none;
        }
    }
    &-section1 {
        position: relative;
        padding-top: calc(51.302vw / 2 + 12.4vw);
        margin-bottom: -11.46vw;
        @media screen and (max-width: $screen-md) {
            padding-top: calc(60vw / 2 + 70px);
            margin-bottom: -47px;
            background-color: $beige;
        }
        &::after {
            @media screen and (max-width: $screen-md) {
                content: "";
                background-color: $beige;
                position: absolute;
                right: 0;
                top: 0;
                background-color: $blanc;
                width: 100%;
                height: 645px;
            }
        }
    }
    .img-full1 {
        @media screen and (max-width: $screen-md) {
            img {
                aspect-ratio: 390/290;
            }
        }
    }
    &-section2 {
        background-color: $marron-fonce;
        padding: vw(220px) 0 vw(260px);
        .flex {
            align-items: center;
            justify-content: center;
        }
        @media screen and (max-width: $screen-md) {
            padding: 110px 0 120px;
            .h1 {
                padding: 70px 0;
            }
        }
    }
    &-section3 {
        background-color: $marron-fonce;
        padding-bottom: vw(190px);
        @media screen and (max-width: $screen-md) {
            padding-bottom: 128px;
        }
        &_grid {
            margin-top: vw(80px);
            column-gap: vw(160px);
            row-gap: vw(100px);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: $screen-md) {
                grid-template-columns: 1fr;
                gap: 80px;
                margin-top: 80px;
            }
            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: vw(40px);
                width: vw(230px);
                @media screen and (max-width: $screen-md) {
                    gap: 40px;
                    width: 100%;
                }
                p {
                    text-align: center;
                    white-space: nowrap;
                }
            }
        }
    }
    &-section4 {
        position: relative;
        .h1 {
            position: absolute;
            top: vw(124px);
            @media screen and (max-width: $screen-md) {
                top: 80px;
                text-align: center;
                width: calc(100% - 60px);
                font-size: 30px;
            }
        }
        .img-full2 {
            @media screen and (max-width: $screen-md) {
                img {
                    aspect-ratio: 390/600;
                }
            }
        }
    }
    &-section5 {
        background: linear-gradient(to right, $beige 42.44%, $blanc 42.44%);
        padding-bottom: vw(340px);
        @media screen and (max-width:$screen-md) {
            background: linear-gradient(to top, $beige 550px, $blanc 550px);
            padding-bottom: 230px;
        }
        .h1 {
            margin-top: vw(200px);
            margin-bottom: vw(42px);
            @media screen and (max-width:$screen-md) {
                margin: 40px 0;
            }
        }
        .gate {
            margin-top: -15vw;
            @media screen and (max-width:$screen-md) {
                margin-top: -140px;
            }
        }
        .bouton {
            color: $noir;
            margin-top: vw(70px);
            @media screen and (max-width:$screen-md) {
                margin-top: 100px;
            }
        }
        .h3 {
            margin-top: -2.6vw;
            padding-right: vw(100px);
            @media screen and (max-width:$screen-md) {
                margin-top: 116px;
                padding-right: 0;
                @include fluid-text(15px, 25px, 1.44, 320px, 390px);
            }
        }
    }

    &-section6 {
        position: relative;
        background-color: $marron-fonce;
        .col {
            position: relative;
            transform: translateY(-50%);
            @media screen and (max-width:$screen-md) {
                &+.col {
                    margin-top: 30px;
                }
            }
            &::before {
                z-index: 5;
                content: '';
                background-color: #101010;
                opacity: .4;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            &::after {
                z-index: 6;
                content: '';
                background-color: $marron;
                opacity: .8;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: all .3s ease;
            }
            &:hover {
                &::after {
                    opacity: .8;
                }
            }
        }
        .fond {
            position: relative;
            width: 100%;
        }
        .container {
            z-index: 7;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            gap: vw(34px);
        }
    }

    &-section7 {
        background-color: $marron-fonce;
        padding-bottom: vw(270px);
        @media screen and (max-width:$screen-md) {
            padding-bottom: 100px;
        }
        .h1 {
            margin-bottom: vw(100px);
            @media screen and (max-width:$screen-md) {
                margin-bottom: 70px;
            }
        }
    }

    &-section8 {
        position: relative;
        padding-top: vw(254px);
        @media screen and (max-width: $screen-md) {
            padding-top: 70px;
            background-color: $beige;
        }
        &::after {
            content: "";
            background-color: $beige;
            height: 100%;
            width: calc(50vw);
            position: absolute;
            right: 0;
            top: 0;
            @media screen and (max-width: $screen-md) {
                background-color: $blanc;
                width: 100%;
                height: 645px;
            }
        }
    }
}
