.etapes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: vw(120px);
    .dates {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        transition: all .3s ease;
        @media screen and (max-width:$screen-md) {
            gap: 34px;
            width: unset;
        }
        &::after {
            content: '';
            background-color: $marron-clair;
            width: 100%;
            height: 1px;
            position: absolute;
            z-index: -1;
        }
        .date {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            padding: 10px 15px;
            padding-top: 13px;
            padding-left: 40px;
            padding-right: 40px;
            background-color: $marron-fonce;
            border: 1px solid $marron-clair;
            border-radius: 100vmax;
            color: $blanc;
            transition: all .3s ease;
            @extend .paragr;
            font-size: 25px!important;
            font-weight: 600;
        
            @media screen and (max-width:$screen-md) {
                height: 50px;
                font-size: 25px!important;
            }
            &.active {
                background-color: $marron-clair;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .infos {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .info {
            display: flex;
            align-items: center;
            gap: vw(70px);
            opacity: 0;
            pointer-events: none;
            transition: all .3s ease;
            height: 100%;
            &.active {
                transition: all .3s ease .3s;
                opacity: 1;
                pointer-events: all;
                .number {
                    transition: all .3s ease .3s;
                    transform: translate(0px, 0px);
                }
            }
            &:not(:first-child) {
                position: absolute;
            }
            @media screen and (max-width:$screen-md) {
                gap: 24px;
                flex-direction: column;
                p {
                    text-align: center
                }
            }
            .number {
                transition: all .3s ease;
                font-size: 25px;
                transform: translate(-70px, 0px);
                @include fluid-text(50px, 80px, 1.1, 991px, 1920px);
                font-weight: 600;
                color: $marron-clair;
                @media screen and (max-width:$screen-md) {
                    font-size: 70px;
                    transform: translate(0px, -40px);
                }
            }
            p {
                @extend .h3;
                max-width: vw(360px);
                @media screen and (max-width:$screen-md) {
                    max-width: 100%;
                }
            }
        }
    }
}