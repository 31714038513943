.carte {
    color: $marron-clair;

    .bigTitle {
        font-size: 4rem;

        @media screen and (max-width: $screen-md) {
            font-size: 30px;
        }
    }

    .simple-container {
        padding-bottom: 10rem;

        @media screen and (max-width: $screen-md) {
            padding-bottom: 100px;
        }
    }

    &_content {
        margin-bottom: vw(50px);

        @media screen and (max-width:$screen-md) {
            margin-bottom: 30px;
        }

        .carte_group {
            border-bottom: 1px solid $marron-clair;
            padding-top: 3rem;
            padding-bottom: 3rem;

            @media screen and (max-width: $screen-md) {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &:first-child {
                border-top: 1px solid $marron-clair;
            }

            &_btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                .plus {
                    display: block;
                    width: 4vw;
                    height: 4vw;
                    position: relative;
                    border: 1px solid $marron-clair;
                    border-radius: 100vmax;

                    @media screen and (max-width: $screen-md) {
                        width: 55px;
                        height: 55px;
                    }

                    &::after,
                    &::before {
                        display: block;
                        width: 38.5%;
                        height: 4px;
                        background-color: $blanc;
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }

                    &::after {
                        transform: translateX(-50%) translateY(-50%) rotate(90deg);
                    }
                }

                &.open {
                    .plus {
                        &::after {
                            opacity: 0;
                        }
                    }
                }
            }

            &_content {
                overflow: hidden;
                transition: all 0.3s ease;

                &.open {
                    padding-top: 20px;
                }
            }

            &_title {
                font-size: 3rem;
                font-weight: bold;
                margin: 0;
                color: $marron-clair;

                @media screen and (max-width: $screen-md) {
                    font-size: 25px;
                }
            }

            .carte_product {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 2rem;

                @media screen and (max-width: $screen-md) {
                    flex-direction: column;
                    margin-bottom: 20px;

                    div:first-child() {
                        max-width: 70%;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .name {
                    font-size: 2.5rem;
                    color: $blanc;

                    @media screen and (max-width: $screen-md) {
                        font-size: 18px;
                    }

                    .option {
                        font-size: 1.2rem;
                        font-style: italic;

                        @media screen and (max-width: $screen-md) {
                            font-size: 12px;
                        }
                    }
                }

                .desc {
                    font-size: 1.6rem;
                    margin-top: 1rem;

                    @media screen and (max-width: $screen-md) {
                        font-size: 16px;
                    }
                }

                .price {
                    @extend .h3;
                    color: $marron-clair;
                }
            }
        }

        .carte_menu {
            .carte_selection {
                margin-top: 3rem;

                @media screen and (max-width: $screen-md) {
                    margin-top: 20px;
                }

                .title {
                    font-size: 2.5rem;
                    margin-bottom: 2rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid $marron-clair;
                    color: $marron-clair;

                    @media screen and (max-width: $screen-md) {
                        font-size: 18px;
                        margin-bottom: 20px;
                        padding-bottom: 10px;
                    }
                }
            }
        }

        .colum-prices {
            &_name {
                margin-bottom: 2rem;

                @media screen and (max-width: $screen-md) {
                    margin-bottom: 0;
                    margin-top: 15px;
                }
            }

            &_name,
            &_content {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                @media screen and (max-width:$screen-md) {
                    justify-content: space-between;
                }

                span {
                    width: vw(120px);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: $screen-md) {
                        width: 30%;
                    }
                }
            }
        }

        .categorieTitles {
            margin-bottom: 5rem;

            @media screen and (max-width: $screen-md) {
                margin-bottom: 40px;
            }

            &_title1 {
                @extend .paragr;
                margin-bottom: 2rem;
                color: $blanc;

                @media screen and (max-width: $screen-md) {
                    margin-bottom: 20px;
                }
            }

            &_title2 {
                font-size: 1.8rem;
                margin-bottom: 3rem;

                @media screen and (max-width: $screen-md) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }
        }

        .vMenu {
            padding-top: 3rem;

            &_title1 {
                font-size: 2.2rem;
                margin-bottom: 2rem;
                font-weight: $bold;
                color: $marron-clair;

                @media screen and (max-width: $screen-md) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            &_title2 {
                font-size: 1.8rem;
                margin-bottom: 3rem;

                @media screen and (max-width: $screen-md) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }

            &_price {
                font-size: 2.4rem;
                font-weight: $bold;
                color: $marron-clair;

                @media screen and (max-width: $screen-md) {
                    font-size: 18px;
                }
            }
        }
    }
}