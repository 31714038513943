.p-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 104;
    width: 100%;
    background-color: $marron-fonce;
    opacity: 0;
    transform: translateY(100%);
    animation: trans-opacity-bot-100 1s ease .3s forwards;

    color: $blanc;
    @extend .nav-text;

    a:hover {
        color: $marron-clair;
    }

    .logo {
        position: absolute;
        margin-left: 50%;
        transform: translateX(-50%);
        transition: all .3s ease;

        @media screen and (max-width:$screen-md) {
            position: absolute;
            top:50%;
            left:50%;
            margin-left: 0;
            transform: translate(-50%,-50%);
        }

        img {
            max-width: 30vw;
            width: 100%;
            min-width:100px;
            height: auto;

            @media screen and (max-width:$screen-md) {
                max-width: 150px;
            }
        }
    }

    &_container {
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width:$screen-md) {
            height: 85px;
        }
    }

    &_menu {
        display: flex;
        align-items: center;
        gap: vw(33px);

        @media screen and (max-width:$screen-md) {
            display: none;
        }
    }

    &_menu2 {
        display: flex;
        align-items: center;
        gap: vw(24px);

        @media screen and (max-width:$screen-md) {
            display: initial;

            .bouton {
                display: none;
            }

            font-size: 14px;
        }
    }

    &.masked {
        background-color: transparent;
    }

    .bt-menu {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: end;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 1;

        @media screen and (min-width:$screen-md) {
            display: none;
        }

        .barre {
            display: block;
            width: 100%;
            background-color: $blanc;
            height: 2px;
            position: relative;
            transform: rotate(0);
            transition: all .3s ease;

            &:nth-child(1) {
                top: -11px;
                transition: all .3s ease;
            }

            &:nth-child(2) {
                opacity: 1;
                transition: all .3s ease;
            }

            &:nth-child(3) {
                bottom: -11px;
                transition: all .3s ease;
                width: 70%;
            }
        }

        &.masked {}

        &.open {

            .barre {
                transition: all .3s ease;

                &:nth-child(1) {
                    width: 70%;
                    top: 2px;
                    transform: rotate(45deg);
                    transition: top .3s ease, transform .3s ease .2s;
                }

                &:nth-child(2) {
                    opacity: 0;
                    transition: all .1s ease;
                }

                &:nth-child(3) {
                    bottom: 2px;
                    transform: rotate(-45deg);
                    transition: bottom .3s ease, transform .3s ease .2s;
                }
            }
        }
    }
}