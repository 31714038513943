.flatpickr-calendar {
    font-family: $font;
    background-color: $beige !important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
    display: none !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: $noir !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: $noir !important;
}

.flatpickr-day.today {
    border-color: $noir !important;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    background-color: $noir !important;
}

.flatpickr-months {
    padding: 10px;
    align-items: center;
}

.flatpickr-current-month {
    display: flex !important; 
    align-items: center;
    justify-content: center;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative !important;
    font-family: 'icomoon' !important;
    width: 21px !important;
    height: 21px !important;
    padding: 0 !important;
    transform: rotate(90deg);

    &::before {
        display: block;
        content: "\e902";
        font-size: 20px;
    }

    svg {
        display: none;
    }
}

.flatpickr-prev-month {
    transform: rotate(-180deg) !important;
}

.flatpickr-next-month {
    transform: rotate(0deg) !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid $noir !important;
}